.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.css-xs0225 {
  width: auto !important;
}
.wallet-top-transcriptions button.text_color {
  font-size: 15px;
}
.wallet-top-transcriptions button svg.text_color {
  font-size: 24px !important;
}
.wallet-inner-tabs-content .inner-tabs-button {
  padding: 14px 14px !important;
  width: auto !important;
  border: solid 2px #3a3a3a !important;
  margin: 0px 5px 0px 0 !important;
  border-radius: 3px !important;
  font-weight: bold !important;
  font-family: "Montserrat", sans-serif;

  border-color: #2d3035 !important;
}
.wallet-inner-tabs-content .inner-tabs-button img {
  margin-right: 7px;
}
.wallet-inner-tabs-content .inner-tabs-button:hover {
  background: #2d3035 !important;
}

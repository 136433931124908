.common-left-div {
  width: 20%;
}
.common-right-div {
  width: 80%;
}
.common-game-container {
  margin-top: 25px;
}
.live-support-main {
  bottom: 25px;
  right: 25px;
}
.live-support-button {
  background: #2c69a8;
  padding: 5px;
  border-radius: 50;
  border-radius: 50%;
}

.live-support-chat-box-main {
  transform: 0.3s;
  width: 15vw;
  height: 20vh;
  background: #33353d;
  animation: chatBoxPop 0.2s linear;
}

.live-support-header {
  padding: 5px;
  background-color: #1d5d9f;
}

.live-support-body a {
  height: 90px;
  font-weight: 500;
}
.live-support-body {
  padding: 0 5px;
}

.live-support-body a span {
  color: white;
}

@media (max-width: 1080px) {
  .live-support-body a span {
    font-size: 11px;
  }
}
@media (max-width: 900px) {
  .live-support-body a span,
  .live-support-header .live-support {
    font-size: 11px;
  }
  .live-support-main {
    z-index: 100;
  }
  .live-support-main {
    bottom: 61px;
  }
  .live-support-chat-box-main {
    width: 20vw;
  }
}
@media (max-width: 700px) {
  .live-support-chat-box-main {
    width: 30vw;
  }
}
@media (max-width: 500px) {
  .live-support-chat-box-main {
    width: 40vw;
  }
}
@media (max-width: 350px) {
  .live-support-chat-box-main {
    width: 50vw;
  }
}
@media (max-width: 280px) {
  .live-support-chat-box-main {
    width: 60vw;
  }
}

@keyframes chatBoxPop {
  0% {
    transform: scaley(0);
  }
  100% {
    transform: scaley(1);
  }
}

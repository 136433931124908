.graph-main-container.my_row.justify {
  margin-bottom: 55px;
}
.betchart-class.my_row.justify.relative.white {
  background: #17181b;
  border-radius: 0px 0px 5px 5px;
  padding: 0px 0px 40px 0px;
  margin-bottom: 15px;
}
.second-container-x1 {
  /* padding-left: 115px; */
  /* transition: 10s linear; */
}
.second-container-x2 {
  padding-left: 288px;
  /* transition: 20s linear; */
}
.second-container-x3 {
  padding-left: 187px;
  /* transition: 60s linear; */
}
.multiplier-container-y1 {
  margin-bottom: 45px;
}
.multiplier-yAxis {
  overflow: scroll;
}
.multiplier-yAxis::-webkit-scrollbar {
  display: none;
}
.multiplier-container-y2 {
  padding-bottom: 235px;
  transition: 40s linear;
}
.animated-curve {
  bottom: 61px;
  left: 99px;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
  width: 75%;
}
.curve-line {
  width: 0%;
  height: 100%;
  /* background-image: linear-gradient(to left, #fff, #6122d5); */
}

/* .toMakeCurve {
  transition: 0s;
} */
.common-header-container {
  width: 100%;
  box-shadow: 0px -20px 50px rgb(0 0 0);
  background: #24262b;
}
img.Logo-img {
  max-width: 100%;
  width: auto;
  max-height: 48px;
}
.mascot-Logo-img {
  max-width: 100%;
  width: auto;
  max-height: 48px;
}
.header-search-container input {
  height: 2.875rem;
  border-radius: 50px;
  border: none;
  background: #1c1e22;
  padding-left: 33px;
  padding-right: 10px;
  width: 100%;
}
.header-search-container form {
  width: 100%;
}
.magnifier-container {
  left: 12px;
}
button.header-signin-button {
  background: transparent;
  color: #40a60b;
  border: none;
}
button.header-signup-button {
  background: linear-gradient(to right, #57d217 50%, #48bb0d 50%);
  border-radius: 50px;
  height: 2.5rem;
  width: 6.25rem;
}
.actions-message-count-container.absolute {
  top: 0;
  left: 15px;
  background: #57d217;
  border: 50px;
  padding: 1px 5px;
  border-radius: 50px;
}
.for_icon {
  color: #6a717b;
}
.for_icon:hover {
  color: #fff;
}
.header-actions-container.my_row {
  gap: 25px;
}
.header-inner-div {
  padding: 10px 0;
  margin: auto;
}
.headerAction-buttons {
  border: none;
  padding: 8px 17px;
  font-size: 14px;
  color: #ccd4dd99 !important;
}
.actionButtonColor {
  background-color: transparent;
}
.signupButton_color {
  background-image: linear-gradient(to right, #6426d8 50%, #783beb 50%);
}
.headerAction-buttons a {
  color: #fff;
  text-decoration: none;
}

button#wallet-button {
  padding: 9px 20px 13px 20px;
}
button#wallet-button span svg {
  display: inline-block;
  vertical-align: bottom;
  margin-right: 3px;
  position: relative;
  top: 1px;
}
button.userLogout-button {
  background: transparent;
  border: none;
  color: #fff;
  background: linear-gradient(45deg, #5ddb1c, #2c8300);
  border-radius: 50px;
  padding: 11px;
  margin-left: 15px;
}
.profile-onHover:hover {
  opacity: 100%;
}
.currency-selection-main > .userCurrency-item:last-child {
  margin-bottom: 0 !important;
}
.userprofile-logout-extend.absolute {
  width: 400px;
  background: #1c1d20;
  right: 0;
  transform: scale(0);
  opacity: 0%;
  transition: 0.5s;
  z-index: 2;
  top: 67px !important;
  opacity: 100%;
  transform: scale(0);
}
.header-menu-buttons-container {
  background: #24262b;
  padding: 7px 8px;
  border-radius: 5px;
}
.header-menu-buttons-container button {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 2px 0;
  transition: 0.3s;
  padding: 7px 3px;
  border-radius: 5px;
}
.header-menu-buttons-container button:hover {
  background-color: #202124;
}
.header-menu-buttons-container button:hover {
  color: white;
}

.userprofile-logout-extend.absolute {
  transition: 0.3s;
  opacity: 0%;
}
.userProfileView:hover .userprofile-logout-extend.absolute {
  top: 45px;
  opacity: 100%;
  transform: scale(1);
}
.hover-logout-container {
  margin-top: 15px;
  background: #24262b;
  padding: 8px;
}
.hover-logout-container:hover {
  color: #ffff;
  transition: 0.2s;
}
.actions-signup-container {
  margin: 0 10px;
}

.hover-logout-container {
  padding: 15px 0;
  margin-top: 5px;
  border-radius: 5px;
}
.userBetCurrency-main-container {
  margin-right: 5px;
}

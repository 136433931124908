@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800&display=swap");

* {
	margin: 0;
	padding: 0;
	outline: none;
	box-sizing: border-box;
}

svg {
	min-width: 20px;
	vertical-align: middle;
	height: auto !important;
}
#inputID::placeholder {
	color: #ff0000;
	opacity: 1;
}

body {
	background-color: #24262b;
	color: #fff;
	font-family: "Montserrat", sans-serif;
	overflow-x: hidden;
}

button {
	cursor: pointer;
}

button:active {
	scale: 0.96 !important;
	transition: 0.1s !important;
}

.input_calander {
	background: #37383c;
	border-radius: 3px;
	padding: 13px;
	width: 90%;
	border: none;
	line-height: 17px;
	font-weight: normal;
	color: #687077;
}

*::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}

/* *::-webkit-scrollbar-track { box-shadow: inset 0 0 5px grey; border-radius: 10px; } */
*::-webkit-scrollbar-thumb {
	background: #2c2c2c;
	border-radius: 10px;
}

*::-webkit-scrollbar-thumb:hover {
	background: #000;
}

::-moz-selection {
	color: #000;
	background: #fff;
}

::selection {
	color: #000;
	background: #fff;
}

.clear {
	clear: both;
	overflow: hidden;
}

span,
p {
	word-wrap: break-word;
	font-family: "Montserrat", sans-serif;
}

input {
	background-color: transparent;
	border: none;
	color: #fff;
	font-weight: bold;
}

.disabled {
	color: #8f8f8f;
}

.success {
	color: #56ff00;
}

input:focus-visible {
	outline: 1px solid #357ccc;
}

textarea:focus-visible {
	outline: 1px solid #357ccc;
}

a {
	text-decoration: none;
}

.font-500 {
	font-weight: 500;
}

.font-600 {
	font-weight: 600;
}

.my_row {
	display: flex;
	flex-wrap: wrap;
}

.row_reverse {
	flex-direction: row-reverse;
}
.column {
	flex-direction: column;
}

.margin-top-10 {
	margin-top: 10px !important;
}

.justify {
	justify-content: center;
	align-items: center;
}

.space-between {
	justify-content: space-between;
	align-items: center;
}
 
.space-evenly {
  justify-content: space-evenly;
  align-items: center;
}
 
.space-around {
	justify-content: space-around;
	align-items: center;
}

.my_row_item_2 {
	width: calc(100% / 2);
}

.my_row_item_3 {
	width: calc(100% / 3);
}

.my_row_item_4 {
	width: calc(100% / 4);
}

.my_row_item_5 {
	width: calc(100% / 5);
}

.my_row_item_6 {
	width: calc(100% / 6);
}

img {
	width: 100%;
	max-width: 100%;
	vertical-align: middle;
	border-style: none;
}

.width-auto {
	width: auto;
}

.max-width {
	width: 100%;
}

.pointer {
	cursor: pointer;
}

button {
	cursor: pointer;
	background-color: transparent;
	border: none;
}

.fixed {
	position: fixed;
}

.relative {
	position: relative;
}
.absolute {
	position: absolute;
}

.ref {
	position: relative;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 500px;
	box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
	background: #17181b;
	color: #f5f6f7;
}

.bold {
	font-weight: bold;
}

.text_color {
	color: rgba(153, 164, 176, 0.6);
	font-family: "Montserrat", sans-serif;
}

.darken-bg {
	background-color: #1e2024;
}

.error {
	color: #ff0000;
	font-family: "Montserrat", sans-serif;
}

.white {
	color: #cfcfcf;
	font-family: "Montserrat", sans-serif;
}

.text_font {
	font-family: "Montserrat", sans-serif;
	color: rgba(153, 164, 176, 0.6);
	font-size: 12px;
}

.page-width {
	max-width: 1368px;
	margin: 0 auto;
	padding: 0 10px;
	width: 100%;
}

.height {
	height: 100%;
}

.inline-block {
	display: inline-block;
}

.width {
	width: 100%;
}

.width-5 {
	width: 5%;
}

.width-10 {
	width: 10%;
}

.width-15 {
	width: 15%;
}

.width-20 {
	width: 20%;
}

.width-25 {
	width: 25%;
}

.width-30 {
	width: 30%;
}

.width-35 {
	width: 35%;
}

.width-40 {
	width: 40%;
}

.width-45 {
	width: 45%;
}

.width-48 {
	width: 48%;
}

.width-49 {
	width: 49%;
}

.width-50 {
	width: 50%;
}

.width-55 {
	width: 55%;
}

.width-60 {
	width: 60%;
}

.width-65 {
	width: 65%;
}

.width-70 {
	width: 70%;
}

.width-73 {
	width: 73%;
}

.width-75 {
	width: 75%;
}

.width-80 {
	width: 80%;
}

.width-85 {
	width: 85%;
}

.width-90 {
	width: 90%;
}

.width-95 {
	width: 95%;
}

.primary-btn {
	border-radius: 5px;
	padding: 14px 20px;
	color: #fff;
	font-family: "Orbitron", sans-serif;
	font-size: 14px;
	letter-spacing: 1px;
}

.primary-btn-spinner {
	background: linear-gradient(0deg, #c0492d 0%, #ebdb57 100%);
}

.amount-button-enabled {
	border: 1px solid #2d69a8;
	background: linear-gradient(109.19deg, #2060a1 11.88%, rgba(62, 156, 81, 0) 119.53%);
}

.amount-button-disable {
	border: 1px solid #585858;
	background: linear-gradient(109.19deg, #3a3a3a 11.88%, rgba(62, 156, 81, 0) 119.53%);
}

.primary-btn-spinner:hover {
	background: linear-gradient(0deg, #c0492d 0%, #ebdb57 100%);
}

.primary-btn-bg {
	background: linear-gradient(0deg, #18599c 0%, #5d8fc4 100%);
}

.primary-btn-bg:hover {
	background: linear-gradient(0deg, #113f6d 0%, #497db1 100%);
}

.orbitron-font {
	font-family: "Orbitron", sans-serif;
	letter-spacing: 1px;
}

.bet-button-bubbles {
	background: url("/public/imgs/bet-button-bubbles.png") no-repeat center center, linear-gradient(0deg, #18599c 0%, #5d8fc4 100%);
	min-height: 62px;
}

.bet-button-bubbles:hover {
	background: url("/public/imgs/bet-button-bubbles.png") no-repeat center center, linear-gradient(0deg, #113f6d 0%, #497db1 100%);
}

.bet-button-bubbles-cancel {
	background: url("/public/imgs/bet-button-bubbles-grey.png") no-repeat center center, linear-gradient(0deg, #4e5154 0%, #969a9f 100%);
	width: 300px;
	min-height: 62px;
}

.bet-button-bubbles-cancel:hover {
	background: url("/public/imgs/bet-button-bubbles-grey.png") no-repeat center center, linear-gradient(0deg, #2f3032 0%, #73777b 100%);
}

.primary-btn-disable {
	background: linear-gradient(0deg, #4e5154 0%, #969a9f 100%);
}

.primary-btn-disable:hover {
	background: linear-gradient(0deg, #2f3032 0%, #73777b 100%);
}

.primary-btn-success {
	background: linear-gradient(0deg, #529c18 0%, #a9c45d 100%);
}

.primary-btn-success:hover {
	background: linear-gradient(0deg, #448512 0%, #94ad4e 100%);
}

.primary-btn-danger {
	background: linear-gradient(0deg, #9c1818 0%, #c45d5d 100%);
}

.primary-btn-danger:hover {
	background: linear-gradient(0deg, #851212 0%, #ad4e4e 100%);
}

.text-justify {
	text-align: justify;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.padding-LeftRight {
	padding: 0 15px !important;
}

.padding-left-10 {
	padding-left: 10px !important;
}

.padding-left-15 {
	padding-left: 15px !important;
}

.padding-left-20 {
	padding-left: 20px !important;
}

.padding-right-10 {
	padding-right: 10px !important;
}

.padding-right-15 {
	padding-right: 15px !important;
}

.padding-right-20 {
	padding-right: 20px !important;
}

.padding-bottom-10 {
	padding-bottom: 10px !important;
}

.padding-bottom-15 {
	padding-bottom: 15px !important;
}

.padding-bottom-20 {
	padding-bottom: 20px !important;
}

.margin-left-10 {
	margin-left: 10px !important;
}

.margin-left-15 {
	margin-left: 15px !important;
}

.margin-left-20 {
	margin-left: 20px !important;
}

.margin-right-10 {
	margin-right: 10px !important;
}

.margin-top-30 {
	margin-top: 30px !important;
}

.margin-top-10 {
	margin-top: 10px !important;
}

.margin-right-15 {
	margin-right: 15px !important;
}

.margin-right-20 {
	margin-right: 20px !important;
}

.margin-bottom-10 {
	margin-bottom: 10px !important;
}

.margin-bottom-15 {
	margin-bottom: 15px !important;
}

.margin-bottom-20 {
	margin-bottom: 20px !important;
}

.margin-bottom-30 {
	margin-bottom: 30px !important;
}

.BankRollButton {
	margin-right: 459px;
	margin-bottom: 34px;
}

.gc {
	color: #43b309;
}

.rc {
	color: orange;
}

.errClr {
	color: red;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.margin-global {
	margin: 20px 0;
}

.light_bgc {
	background-color: #1e2024;
}

.dark_bgc {
	background-color: #17181b;
}

.center_align {
	align-items: center;
}

.left_text {
	text-align: left;
}

.centered_text {
	text-align: center;
}

.border-none {
	border: none;
}

.sidebarbtn {
	display: block;
	font-size: 1.5em;
	margin-block-start: 0.83em;
	margin-block-end: 0.83em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-weight: bold;
}

.uppercase {
	text-transform: uppercase;
}

.secondary_text {
	color: #98a7b5;
}

.align-start {
	align-items: flex-start;
}

.end {
	justify-content: flex-end;
}

.start {
	justify-content: flex-start;
}

.fr {
	float: right;
}

.fl {
	float: left;
}

.help-item {
	color: #656c75;
}

.help-item:hover {
	color: #fff;
}

.spinner-main-container {
	border-radius: 3px;
}

.all-player-popup {
	border-radius: 3px !important;
	height: auto !important;
}

.transections-history-bill-left {
	width: 90px;
}

.tarnsections-history-bill-right {
	width: calc(100% - 90px);
}

.history-myHistory-data-container {
	padding: 0 10px;
}

.trends-chat-on-mobile {
	text-align: right;
	margin-top: 10px;
}

.trends-chat-on-mobile button {
	border-radius: 3px;
	color: #5f636d;
	display: inline-block;
	font-size: 12px;
	line-height: 34px;
	margin-left: 10px;
	padding: 0 7px;
	vertical-align: middle;
}

.trends-chat-on-mobile button img {
	vertical-align: middle;
	max-height: 20px;
	margin-right: 4px;
}

.trends-chat-on-mobile button svg {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: 1em;
	height: 1em;
	display: inline-block;
	fill: currentColor;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	-webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	font-size: 1.5rem;
	width: 24px;
}

.table-style {
	border: solid 1px #333941;
	border-bottom: none;
	border-right: none;
}

.table-style th {
	border-bottom: solid 1px #333941;
	padding: 7px;
	color: #98a7b5;
	font-size: 14px;
	background: #333941;
}

.table-style td {
	border-bottom: solid 1px #333941;
	padding: 5px;
	font-size: 14px;
	border-right: solid 1px #333941;
}

.gameHash-container a {
	margin-left: 15px;
}
.css-19kzrtu {
	padding: 0 0 15px 0 !important;
}

.css-hip9hq-MuiPaper-root-MuiAppBar-root {
	background-color: transparent !important;
}

.wallet-dropdown-field-max>div label {
	margin-bottom: -5px !important;
	display: block;
	text-align: right;
	font-size: 12px;
	margin-right: 7px;
	margin-top: -3px;
}
.transections-history-data:hover {
	transition: 0.5s;
	background: #242323;
}

.sideBarMenu-logo-container img {
	max-width: 122px;
}

@-webkit-keyframes scale-wallet-popup-steps {
	0% {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
	}

	50% {
		-webkit-transform: scale(0.8);
		-ms-transform: scale(0.8);
		transform: scale(0.8);
	}

	to {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes scale-wallet-popup-steps {
	0% {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
	}

	50% {
		-webkit-transform: scale(0.8);
		-ms-transform: scale(0.8);
		transform: scale(0.8);
	}

	to {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
	}
}

.wallet-popup-steps .bonus-tit {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding: 0 0 10px 0;
}

.wallet-popup-steps .bonus-tit b {
	display: inline-block;
	height: 1rem;
	width: 1.875rem;
	text-align: center;
	line-height: 1rem;
	color: #000;
	font-size: 0.75rem;
	border-radius: 0.125rem;
	background: #ffca00;
}

.wallet-popup-steps .bonus-tit .more {
	cursor: pointer;
}

.wallet-popup-steps .bonus-list {
	position: relative;
	height: 80px;
	margin-bottom: 12px;
}

.wallet-popup-steps .bonus-list i {
	position: absolute;
	left: 0.75rem;
	top: 0.4375rem;
	display: block;
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 0.375rem;
	border: 1px solid #383b3e;
	background-color: transparent;
}

.wallet-popup-steps .bonus-list i:before {
	content: "";
	width: 0.375rem;
	height: 0.375rem;
	left: 0.125rem;
	top: 0.125rem;
	border-radius: 50%;
	background-color: transparent;
	position: absolute;
}

.wallet-popup-steps .bonus-item {
	position: absolute;
	cursor: pointer;
	left: 0;
	top: 0;
	height: 100%;
	width: 34%;
	padding: 0.9375rem 0.625rem 0.9375rem 1.75rem;
}

.wallet-popup-steps .bonus-item.item-0 {
	padding-left: 1.25rem;
}

.wallet-popup-steps .bonus-item.item-1 {
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translate(-50%);
}

.wallet-popup-steps .bonus-item.item-2 {
	left: auto;
	right: 0;
}

.wallet-popup-steps .bonus-item svg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: auto;
}

.wallet-popup-steps .bonus-item .item-cont {
	position: relative;
	z-index: 1;
}

.wallet-popup-steps .bonus-item .tit {
	color: red;
	white-space: nowrap;
	font-size: 0.75rem;
}

.wallet-popup-steps .bonus-item .bonus-box {
	position: relative;
	padding-left: 3.125rem;
	height: 2.625rem;
	color: #ffca00;
	margin-top: 4px;
	margin-left: 13px;
}

.wallet-popup-steps .bonus-item .bonus-box .img {
	position: absolute;
	width: 2.625rem;
	height: 2.625rem;
	left: 0;
}

.wallet-popup-steps .bonus-item .bonus-box .img .img-treasure {
	position: relative;
	width: 100%;
	margin-top: 0.1875rem;
}

.wallet-popup-steps .bonus-item .bonus-box .img .img-bg {
	position: absolute;
	width: 100%;
	height: 100%;
}

.wallet-popup-steps .bonus-item .bonus-box .bonus-num {
	font-size: 1.375rem;
	line-height: 1.5rem;
	white-space: nowrap;
}

.wallet-popup-steps .bonus-item .bonus-box .bonus-txt {
	font-size: 0.875rem;
	line-height: 1rem;
}

.wallet-popup-steps .active svg path {
	stroke: #ffca00;
}

.wallet-popup-steps .active .bonus-box .img {
	-webkit-animation: scale-wallet-popup-steps 2s infinite linear;
	animation: scale-wallet-popup-steps 2s infinite linear;
}

.wallet-popup-steps .active .bonus-box {
	color: #ffca00;
}

.wallet-popup-steps .active i {
	border-color: #ffca00;
}

.wallet-popup-steps .active i:before {
	background-color: #ffca00;
}

.wallet-popup-steps-slide {
	background: #1e2024;
	overflow-y: auto;
	padding: 20px 0;
}

.wallet-popup-steps-slide p {
	margin: 0;
	margin-bottom: 1rem;
}

.wallet-popup-steps-slide p:first-child {
	margin-top: 0.5rem;
}

.wallet-popup-steps-slide .item {
	padding-top: 1.875rem;
}

.wallet-popup-steps-slide .item .title {
	color: #2fa5cd;
	font-weight: 700;
	line-height: 1.25rem;
	margin: 0;
	font-size: 15px;
}

.wallet-popup-steps-slide .item .deposit-level-info {
	width: 100%;
	height: 12.75rem;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0 5%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.wallet-popup-steps-slide .item .deposit-level-info.first {
	background: url(/public/imgs/1st.png) no-repeat center;
	background-size: 100% 100%;
}

.wallet-popup-steps-slide .item .deposit-level-info.second {
	background: url(/public/imgs/2nd.png) no-repeat center;
	background-size: 100% 100%;
}

.wallet-popup-steps-slide .item .deposit-level-info.third {
	background: url(/public/imgs/3rd.png) no-repeat center;
	background-size: 100% 100%;
}

.wallet-popup-steps-slide .item .deposit-level-info.fourth {
	background: url(/public/imgs/4th.png) no-repeat center;
	background-size: 100% 100%;
}

.wallet-popup-steps-slide .item .deposit-level-info p {
	margin: 23% 0 0;
	width: 32%;
	text-align: center;
	font-weight: 700;
	font-size: 1.75rem;
	padding-left: 0.25rem;
}

.wallet-popup-steps-slide .item .deposit-level-info>p:first-child {
	margin-top: 24%;
}

.wallet-popup-steps-slide .item .deposit-level-info>p:last-child {
	margin-top: 21%;
}

.wallet-popup-steps-slide .item .deposit-level-info .b {
	color: #000;
}

.wallet-popup-steps-slide .item .deposit-level-info .w {
	color: #fff;
}

.wallet-popup-steps-slide .item .deposit-level-info .y {
	color: #fbcf12;
}

.wallet-popup-steps-slide .item .word {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	font-size: 15px;
	line-height: 22px;
}

.wallet-popup-steps-slide .item img {
	width: 100%;
	height: auto;
	min-height: 10rem;
}

.wallet-popup-steps-slide .item.last {
	margin-bottom: 1.875rem;
}

.wallet-popup-steps-slide .deposit-btn {
	margin: 0 auto;
	margin-bottom: 1.875rem;
	width: 20.5rem;
	height: 3.5rem;
}
.global-chat-message.my_message {
	flex-direction: row-reverse;
}

.global-chat-message .message-user-img-container {
	align-self: end;
	display: flex;
}

.global-chat-message.my_message .message-user-img-container {
	align-self: end;
	display: flex;
	margin: 0 0 0 15px !important;
}

.global-chat-message.my_message .message-user-img-container {
	display: none;
}

.global-chat-message.my_message .message-username-container {
	text-align: right !important;
}

.global-chat-message.my_message .message-userMessage-container {
	color: #c4cfd9 !important;
	background: #404550 !important;
}

.global-chat-message.my_message .message-username-container span:nth-child(1) {
	float: right;
	margin: 4px 0 0 25px !important;
}

/* Smartphone  */
.crashGame-history-container {
	margin-bottom: 20px;
}

.crashGame-history-container {
	margin-bottom: 20px;
	width: 100%;
	margin: 0 auto;
}

.dot-dot-type {
	background-color: #2d3035;
}

.dot-dot-type:before {
	content: "";
	display: block;
	padding-top: 100%;
}

.dot-dot-type:after {
	content: "";
	position: absolute;
	left: 15%;
	top: 15%;
	width: 70%;
	height: 70%;
	border-radius: 50%;
}

.dot-dot-type.type-0:after {
	background-color: #2d3035;
}

.dot-dot-type.type-1:after {
	background-color: #ed6300;
}

.dot-dot-type.type-2:after {
	background-color: #3bc117;
}

.dot-dot-type.type-3:after {
	background-color: #f6c722;
}

.column {
	flex-direction: column;
}

/* Loading Animation Start Here  */
.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 80px;
}

.lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 13px;
	height: 13px;
	border-radius: 50%;
	background: #d6d6d6;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
	left: 56px;
	animation: lds-ellipsis3 0.6s infinite;
}

.accepted-network-main-img:hover {
	opacity: 0%;
	transition: 0.1s;
}

.animated-coin {
	animation: translation 3s infinite linear;
}

.edit-profile-field-fregment-container {
	margin-bottom: 10px;
}

.preLoader-animation {
	animation: preLoader 2s infinite linear;
}

@keyframes preLoader {
	0% {
		filter: brightness(1);
	}

	25% {
		filter: brightness(1.5);
	}

	50% {
		transform: translateY(2);
	}

	75% {
		transform: translateY(1.5);
	}

	100% {
		transform: translateY(1);
	}
}

@keyframes translation {
	25% {
		transform: translateY(10px);
	}

	50% {
		transform: translateY(20px);
	}

	75% {
		transform: translateY(10px);
	}

	100% {
		transform: translateY(0px);
	}
}

.lotery {
	animation: Bubble 3s infinite linear;
}

@keyframes Bubble {

	/* 50% { transform: scale(0.5); } */
	75% {
		transform: scale(0.75);
	}

	100% {
		transform: scale(1);
	}
}

.infiniteSpin {
	animation: rotation 1s infinite linear;
}

.none {
	display: none;
	width: 0%;
}

.Mogile-Logo-img {
	display: none;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0);
	}
}

@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(24px, 0);
	}
}

.MuiTabs-indicator {
	display: none;
}

/* Loading Animation Ends Here  */
.profile-expand-icon.justified.relative svg:hover {
	color: white;
	transition: 0.1s;
}

span.here {
	position: absolute;
	z-index: 1;
	top: 50% lef;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 10px;
	font-size: bold;
}

.setupSignupFields {
	background-color: #24262b;
	border: none;
	color: #fff;
}

.nameField {
	padding: 20px 12px;
}

.date-field-container {
	width: 30%;
}

.DateField {
	width: 86%;
	padding: 20px 9px;
}

/* tablet  */
.signupSetup-for-margin {
	margin-top: 15px;
}

.selector {
	padding: 10px 0px 10px 20px;
	font-size: 11px;
	border-top-right-radius: 1.125rem;
	border-bottom-right-radius: 1.125rem;
}

.tarnsections-bill-swap-amount {
	font-size: 10px;
}

.bet-button {
	background-image: linear-gradient(to right, #6426d8 50%, #783beb 50%) !important;
}

.empty-max-width {
	max-width: 130px;
}

.wallet-scroll-whole {
	max-height: 395px;
	min-height: 395px;
	overflow-y: auto;
	padding: 25px 20px;
}

.deposit-popup {
	padding: 40px !important;
	height: auto !important;
	border-radius: 5px !important;
}

.lds-ripple {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.lds-ripple div {
	position: absolute;
	border: 4px solid #fff;
	opacity: 1;
	border-radius: 50%;
	animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
	animation-delay: -0.5s;
}

@keyframes lds-ripple {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 0;
	}

	4.9% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 0;
	}

	5% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
	}

	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
	}
}

/*/////////////////////////////////////////////////////////
AFFILIATE PAGE
/////////////////////////////////////////////////////////*/
.affiliate-toggle-icon {
	margin-right: 10px;
	cursor: pointer;
}

.affiliate-accordion-contents {
	margin-bottom: 40px;
}

.affiliate-accordion-contents h1 {
	text-align: center;
	margin-bottom: 30px;
	font-size: 30px;
}

.affiliate-accordion-title {
	border-top: 1px solid #5f6064;
	padding: 20px 30px 20px 0;
	cursor: pointer;
	position: relative;
	font-size: 16px;
	margin: 0;
}

.affiliate-accordion-title::after {
	content: "";
	position: absolute;
	right: 1px;
	top: 50%;
	transition: all 0.2s ease-in-out;
	display: block;
	width: 8px;
	height: 8px;
	border-top: solid 2px #999;
	border-right: solid 2px #999;
	transform: translateY(-50%) rotate(135deg);
}

.affiliate-accordion-content {
	max-height: 0;
	overflow: hidden;
	transition: all 0.5s ease;
}

.affiliate-accordion-content p {
	margin-bottom: 20px;
	font-size: 14px;
	line-height: 24px;
}

.affiliate-accordion-item.is-open .affiliate-accordion-content {
	max-height: 800px;
	overflow: auto;
	transition: all 0.5s ease;
}

.affiliate-accordion-item.is-open .affiliate-accordion-title::after {
	transform: translateY(-50%) rotate(315deg);
	border-color: #2fa5cd;
}

/*Affiliate Search Page*/
.affiliate-search {
	background: #1c1e22;
	border-radius: 8px;
	margin-bottom: 30px;
	padding: 50px 50px 40px 50px;
}

.affiliate-search h1 {
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 30px;
}

.total-reward-total-friend {
	background: #24262b;
	border-radius: 4px;
	padding: 25px;
	margin-bottom: 30px;
}

.total-reward-total-friend h2 {
	font-size: 32px;
	font-weight: 700;
	line-height: 32px;
}

.total-reward-total-friend h2 b {
	background: linear-gradient(0deg, #18599c 0%, #5d8fc4 100%);
	color: transparent;
	-webkit-background-clip: text;
	font-weight: 700;
}

.total-reward-total-friend p {
	margin-bottom: 20px;
	font-weight: 20px;
}

.affiliate-search-fields p {
	color: #687077;
	font-size: 14px;
	margin-bottom: 10px;
}

.affiliate-search-fields .affiliate-search-fields-style {
	background: #37383c;
	border-radius: 3px;
	padding: 13px;
	width: 100%;
	border: none;
	line-height: 17px;
	font-weight: normal;
	color: #687077;
}

.affiliate-search-fields .calendar {
	width: 18px;
	position: absolute;
	top: 12px;
	right: 10px;
}
.affiliate-search-records {
	margin-bottom: 30px;
}

.affiliate-search-records table {
	background: #1e2024;
	width: 100%;
	text-align: center;
	border-radius: 8px;
	border: none;
}

.affiliate-search-records table th {
	color: #687077;
	font-size: 16px;
	font-weight: 500;
	padding: 20px 0;
	border: none;
	border-bottom: solid 1px #363636;
}

.affiliate-search-records table td {
	border: none;
	padding: 15px 0;
	color: #687077;
	font-size: 15px;
}

.affiliate-search-records table td .currency-icon {
	max-width: 15px;
}

/*Affiliate Side Pannel*/
.affiliate-side-panel-transparent {
	background: rgb(0 0 0 / 70%);
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 19;
	display: block !important;
}

.affiliate-side-pannel {
	background: #191a1d;
	position: absolute;
	width: 380px;
	z-index: 99;
	left: 0;
	top: 0;
	bottom: 0;
	height: 100%;
	padding: 15px;
}

.affiliate-side-pannel-content {
	position: relative;
}

.affiliate-side-pannel-content .close-icon {
	position: absolute;
	top: 0;
	right: 0px;
	line-height: 10px;
}

.affiliate-side-pannel-content .affiliate-accordion {
	padding-top: 35px;
}

.affiliate-side-pannel-content .affiliate-accordion-item {
	margin-bottom: 20px;
}

.affiliate-side-pannel-content .affiliate-accordion-title {
	padding: 13px;
	border: none;
	background: #101010;
	border-radius: 3px;
	color: #9c9c9c;
	font-weight: normal;
	font-size: 16px;
	font-weight: 500;
}

.affiliate-side-pannel-content .affiliate-accordion-title svg {
	margin-right: 10px;
}

.affiliate-side-pannel-content .affiliate-accordion-title::after {
	right: 15px;
}

.affiliate-side-pannel-content .affiliate-accordion-content p {
	margin: 0;
}

.affiliate-side-pannel-content .affiliate-accordion-item.is-open .affiliate-accordion-title {
	color: #fff;
}

.affiliate-side-pannel-content .affiliate-accordion-item.is-open .affiliate-accordion-title::after {
	border-color: #fff;
}

.affiliate-side-pannel-content .affiliate-accordion-item.is-open .affiliate-accordion-title svg path {
	fill: #fff;
}

.affiliate-left-pannel-menu {
	margin: 15px 0 0 10px;
	list-style: none;
}

.affiliate-left-pannel-menu li {
	position: relative;
	margin: 15px 0 0px 0;
}

.affiliate-left-pannel-menu li a {
	color: #9c9c9c;
	font-size: 16px;
	display: inline-block;
}

.affiliate-left-pannel-menu li a img {
	margin-right: 7px;
  width: 22px;
  height: 22px;
  margin-top: -2px;
}

.affiliate-left-pannel-menu li a .affiliate-dropdown-menu-arrow {
	margin: auto;
	height: 12px;
	position: absolute;
	right: 5px;
	top: 5px;
}

.affiliate-left-pannel-menu li ul {
	max-height: 0;
	transition: max-height 0.4s ease-out;
	overflow: hidden;
	margin: 0px 0 0 33px;
}

.affiliate-left-pannel-menu li:hover ul {
	max-height: 500px;
	transition: max-height 1s ease-in;
}

.affiliate-left-pannel-menu li ul li a img {
	margin-right: 5px;
	width: 24px;
	height: 24px;
}
.affiliate-left-pannel-menu li ul li{}
.box-1 {
	background: #1c1e22;
	border-radius: 8px;
	padding: 20px 0;
	margin: 15px 0 10px 0;
}

.box-2 {
	background: #2c2e32;
	border-radius: 8px;
	padding: 20px 0;
}
.box-1-content {
	margin: 50px 0 0 50px;
}

.box-1-content h1 {
	font-size: 40px;
	margin-bottom: 20px;
}

.box-1-content h2 {
	font-size: 35px;
	margin-bottom: 20px;
}

.box-1-content h2 b {
	background: linear-gradient(0deg, #18599c 0%, #5d8fc4 100%);
	color: transparent;
	-webkit-background-clip: text;
	margin-bottom: 20px;
}

.box-1-content a {
	margin-bottom: 20px;
}

.box-1-content p {
	font-size: 13px;
	color: #717171;
	margin-bottom: 20px;
}
.box-2-content {
	margin: 30px 0 0 30px;
}

.box-2-content h1 {
	font-size: 25px;
	margin-bottom: 20px;
}

.box-2-content h1 b {
	background: linear-gradient(0deg, #18599c 0%, #5d8fc4 100%);
	color: transparent;
	-webkit-background-clip: text;
	margin-bottom: 20px;
}

.box-2-content p {
	color: #717171;
	margin-bottom: 30px;
}

/*/////////////////////////////////////////////////////////
GRID SYSTEM
/////////////////////////////////////////////////////////*/
.main-wrapper-container .clearfix::after {
	display: block;
	clear: both;
	content: "";
}

.main-wrapper-container .visible {
	visibility: visible !important;
}

.main-wrapper-container .invisible {
	visibility: hidden !important;
}

.main-wrapper-container .hidden-xs-up {
	display: none !important;
}

.ascend-descend-buttons-container {
	gap: 1px;
}

.page-action-button {
	background-color: #1e2024;
}

.page-action-button:hover {
	transition: 0.3s;
	color: #a5a5a5;
	background-color: #3e3e3e73;
}

.sideBar-container {
	background: #1e2024;
	padding: 20px 0;
	z-index: 99;
}
.top-level-parent {
	height: 100%;
	overflow: hidden;
}
.sidebar-action-button {
	padding: 10px;
	border-radius: 5px;
	background-color: #17181b;
}

.sidebar-action-button:hover {
	color: #fff;
	background-color: #333436;
}

.side-bar-navigation-action-button {
	padding: 5px;
	color: #656c75;
	border-radius: 4px;
}

.side-bar-navigation-action-button:hover {
	background-color: #333436;
	color: white;
}

.side-bar-navigation-action-button svg {
	font-size: 22px;
}

.side-bar-navigation-actions {
	gap: 20px;
}
.side-bar-colored-action-button img {
	max-width: 45px;
}
.side-bar-navigation-actions-container {
	display: flex;
	flex-direction: column;
	gap: 50px;
	margin-top: 39px;
}

.side-bar-colored-navigation-actions {
	gap: 20px;
}
.tasks-button-hover {
	background-color: rgb(23, 24, 27);
}

.hover-spinner,
.simple-spinner img {
	max-width: 60%;
}

.tasks-button:hover .hover-spinner {
	display: block;
	left: 60px;
	background-color: rgb(23, 24, 27);
}

.hover-spinner {
	display: block;
	padding: 3px 0;
	top: 0;
	left: 67px;
}

.simple-spinner {
	display: none;
	top: 3px;
}

.side-bar-language {
	padding: 13px;
	background-color: #191a1d;
	font-weight: 500;
	font-size: 11px;
}

.side-bar-language:hover {
	background-color: rgb(16 16 16);
}

.hidden-language-selector {
	top: -200px;
	opacity: 0;
}

.shown-language-selector {
	top: 20px;
	opacity: 100%;
	transition: 0.3s;
}

.language-selector-arrow-container {
	transition: 0.3s;
}

.rotate-icon {
	transform: rotate(-180deg);
}
.active-language-indicator {
	background-color: #3bc117;
}

.inactive-language-indicator {
	background-color: #4f4f4f;
}

.language-active-indicator {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	right: 20px;
	top: 50%;
	transform: translatey(-50%);
}

.css-919eu4 {
	background-color: rgba(0, 0, 0, 0.8) !important;
}

input:focus {
	outline: none;
}

#chat-widget-container {
	bottom: 0px !important;
}

select {
	-webkit-appearance: none;
	appearance: none;
	background-image: url("/public/imgs/select-dropdown-arrow.svg") !important;
	background-repeat: no-repeat !important;
	background-position: center right 5px !important;
}
.coin-buttons-container {
	margin-bottom: 10px;
}
.signup-spin-left-content {
	background: linear-gradient(225deg, #fffb90 11.9%, #fbea78 22.33%, #f8dc65 29.53%, #e6c758 31.82%, #c5a041 36.72%, #ad8330 41.23%, #9e7226 45.14%, #996c22 48.03%, #9d7126 50.23%, #aa8131 52.99%, #be9b42 56.06%, #dabe5b 59.33%, #fbe878 62.5%, #ffa 68.84%, #fbe878 73.33%, #a4631b 85.62%);
	border-radius: 50%;
}

.signup-spin-left-content .network-img-container img {
	background: #fff;
	border-radius: 50%;
	border: solid 1px #fff;
}

.spin-signup-right-content {
	position: relative;
}

.spin-signup-right-content .spinnerCat {
	position: absolute;
	width: 150px;
	top: 230px;
	left: 20px;
	-webkit-animation: spinnerCat 2s 0s infinite alternate;
	animation: spinnerCat 2s 0s infinite alternate;
	z-index: 1;
}

.spin-signup-right-content-inner {
	position: absolute;
	right: 40px;
	top: 40px;
	-webkit-animation: spin-signup-right-content-inner 2s 0s infinite alternate;
	animation: spin-signup-right-content-inner 2s 0s infinite alternate;
}

.spin-signup-right-content-inner .daily-text {
	margin-bottom: 10px;
}

.spin-signup-right-content-inner .daily-text span {
	line-height: 35px;
	font-size: 50px !important;
	font-style: italic;
}

.spin-signup-right-content-inner .win-text span {
	line-height: 35px;
	font-size: 50px !important;
	font-style: italic;
}
.spin-signup-right-content-inner:nth-child(2) {
	top: 43px;
	right: 42px;
	color: transparent;
	-webkit-text-stroke: 0.5px rgb(255 255 255 / 70%);
	z-index: 3;
}

.spin-signup-right-content-inner:nth-child(3) {
	color: #34e7ff;
	z-index: 2;
}

.spin-signup-right-content-inner:nth-child(4) {
	color: rgba(52, 231, 255, 0.3);
	right: 45px;
	top: 47px;
}
@-webkit-keyframes spinnerCat {
	0% {
		transform: translateY(-5%);
	}

	to {
		transform: translateY(3%);
	}
}

@keyframes spinnerCat {
	0% {
		transform: translateY(-5%);
	}

	to {
		transform: translateY(3%);
	}
}
@-webkit-keyframes spin-signup-right-content-inner {
	0% {
		transform: scale(1.2);
	}

	to {
		transform: scale(1);
	}
}

@keyframes spin-signup-right-content-inner {
	0% {
		transform: scale(1.4);
	}

	to {
		transform: scale(1);
	}
}
.spinner-spin-the-wheel {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 204.43px;
	height: 61.21px;
	background-image: url("/public/imgs/spinner-spin-the-wheel.webp");
	background-size: 204.43px;
	grid-gap: 8px;
	gap: 8px;
	position: absolute;
	top: 47%;
	right: 20%;
}

.spinner-spin-the-wheel p:nth-child(1) {
	font-size: 14px;
}

.spinner-spin-the-wheel p:nth-child(2) {
	color: #fff500;
	font-weight: 700;
	line-height: 16px;
	font-size: 15px;
	text-align: center;
}
.spinner-btn {
	position: absolute;
	bottom: 10%;
	right: 31%;
	-webkit-animation: spin-signup-right-content-inner 2s 0s infinite alternate;
	animation: spin-signup-right-content-inner 2s 0s infinite alternate;
	overflow: hidden;
}

.spinner-btn:after {
	box-shadow: 0 0 5px 5px #fff;
	content: "";
	height: 100%;
	opacity: 0.4;
	position: absolute;
	left: 0;
	top: 0;
	transform: rotate(15deg);
	animation: spinner-btn-after 3s infinite;
	animation-timing-function: linear;
}
@-webkit-keyframes spinner-btn-after {
	0% {
		transform: translateX(0) rotate(15deg);
	}

	to {
		transform: translateX(2000px) rotate(15deg);
	}
}

@keyframes spinner-btn-after {
	0% {
		transform: translateX(0) rotate(15deg);
	}

	to {
		transform: translateX(2000px) rotate(15deg);
	}
}
.w-25 {
	width: 25% !important;
}

.w-50 {
	width: 50% !important;
}

.w-75 {
	width: 75% !important;
}

.w-100 {
	width: 100% !important;
}

.w-auto {
	width: auto !important;
}

.mw-100 {
	max-width: 100% !important;
}
.d-inline {
	display: inline !important;
}

.d-inline-block {
	display: inline-block !important;
}

.d-block {
	display: block !important;
}

.d-flex {
	display: flex !important;
}

.d-none {
	display: none !important;
}
.justify-content-center {
	justify-content: center !important;
}

.justify-content-between {
	justify-content: space-between !important;
}

.justify-content-around {
	justify-content: space-around !important;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.justify-content-end {
	justify-content: flex-end !important;
}

.justify-content-center {
	justify-content: center !important;
}
.align-items-start {
	align-items: flex-start !important;
}

.align-items-end {
	align-items: flex-end !important;
}

.align-items-center {
	align-items: center !important;
}
.top-0 {
	top: 0 !important;
}

.top-50 {
	top: 50% !important;
}

.top-100 {
	top: 100% !important;
}
.bottom-0 {
	bottom: 0 !important;
}

.bottom-50 {
	bottom: 50% !important;
}

.bottom-100 {
	bottom: 100% !important;
}
.start-0 {
	left: 0 !important;
}

.start-50 {
	left: 50% !important;
}

.start-100 {
	left: 100% !important;
}
.end-0 {
	right: 0 !important;
}

.end-50 {
	right: 50% !important;
}

.end-100 {
	right: 100% !important;
}
.translate-middle {
	transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
	transform: translateX(-50%) !important;
}

.translate-middle-y {
	transform: translateY(-50%) !important;
}
.position-static {
	position: static !important;
}

.position-relative {
	position: relative !important;
}

.position-absolute {
	position: absolute !important;
}

.position-fixed {
	position: fixed !important;
}

.position-sticky {
	position: -webkit-sticky !important;
	position: sticky !important;
}
.order-first {
	order: -1 !important;
}

.order-0 {
	order: 0 !important;
}

.order-1 {
	order: 1 !important;
}

.order-2 {
	order: 2 !important;
}

.order-3 {
	order: 3 !important;
}

.order-4 {
	order: 4 !important;
}

.order-5 {
	order: 5 !important;
}

.order-last {
	order: 6 !important;
}
.m-0 {
	margin: 0 !important;
}

.m-1 {
	margin: 0.25rem !important;
}

.m-2 {
	margin: 0.5rem !important;
}

.m-3 {
	margin: 1rem !important;
}

.m-4 {
	margin: 1.5rem !important;
}

.m-5 {
	margin: 3rem !important;
}

.m-auto {
	margin: auto !important;
}
.mt-0 {
	margin-top: 0 !important;
}

.mt-1 {
	margin-top: 0.25rem !important;
}

.mt-2 {
	margin-top: 0.5rem !important;
}

.mt-3 {
	margin-top: 1rem !important;
}

.mt-4 {
	margin-top: 1.5rem !important;
}

.mt-5 {
	margin-top: 3rem !important;
}

.mt-auto {
	margin-top: auto !important;
}
.me-0 {
	margin-right: 0 !important;
}

.me-1 {
	margin-right: 0.25rem !important;
}

.me-2 {
	margin-right: 0.5rem !important;
}

.me-3 {
	margin-right: 1rem !important;
}

.me-4 {
	margin-right: 1.5rem !important;
}

.me-5 {
	margin-right: 3rem !important;
}

.me-auto {
	margin-right: auto !important;
}
.mb-0 {
	margin-bottom: 0 !important;
}

.mb-1 {
	margin-bottom: 0.25rem !important;
}

.mb-2 {
	margin-bottom: 0.5rem !important;
}

.mb-3 {
	margin-bottom: 1rem !important;
}

.mb-4 {
	margin-bottom: 1.5rem !important;
}

.mb-5 {
	margin-bottom: 3rem !important;
}

.mb-auto {
	margin-bottom: auto !important;
}
.ms-0 {
	margin-left: 0 !important;
}

.ms-1 {
	margin-left: 0.25rem !important;
}

.ms-2 {
	margin-left: 0.5rem !important;
}

.ms-3 {
	margin-left: 1rem !important;
}

.ms-4 {
	margin-left: 1.5rem !important;
}

.ms-5 {
	margin-left: 3rem !important;
}

.ms-auto {
	margin-left: auto !important;
}
.mx-0 {
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.mx-1 {
	margin-right: 0.25rem !important;
	margin-left: 0.25rem !important;
}

.mx-2 {
	margin-right: 0.5rem !important;
	margin-left: 0.5rem !important;
}

.mx-3 {
	margin-right: 1rem !important;
	margin-left: 1rem !important;
}

.mx-4 {
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important;
}

.mx-5 {
	margin-right: 3rem !important;
	margin-left: 3rem !important;
}

.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}
.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.my-1 {
	margin-top: 0.25rem !important;
	margin-bottom: 0.25rem !important;
}

.my-2 {
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

.my-3 {
	margin-top: 1rem !important;
	margin-bottom: 1rem !important;
}

.my-4 {
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
}

.my-5 {
	margin-top: 3rem !important;
	margin-bottom: 3rem !important;
}

.my-auto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}
.p-0 {
	padding: 0 !important;
}

.p-1 {
	padding: 0.25rem !important;
}

.p-2 {
	padding: 0.5rem !important;
}

.p-3 {
	padding: 1rem !important;
}

.p-4 {
	padding: 1.5rem !important;
}

.p-5 {
	padding: 3rem !important;
}
.pt-0 {
	padding-top: 0 !important;
}

.pt-1 {
	padding-top: 0.25rem !important;
}

.pt-2 {
	padding-top: 0.5rem !important;
}

.pt-3 {
	padding-top: 1rem !important;
}

.pt-4 {
	padding-top: 1.5rem !important;
}

.pt-5 {
	padding-top: 3rem !important;
}
.pe-0 {
	padding-right: 0 !important;
}

.pe-1 {
	padding-right: 0.25rem !important;
}

.pe-2 {
	padding-right: 0.5rem !important;
}

.pe-3 {
	padding-right: 1rem !important;
}

.pe-4 {
	padding-right: 1.5rem !important;
}

.pe-5 {
	padding-right: 3rem !important;
}
.pb-0 {
	padding-bottom: 0 !important;
}

.pb-1 {
	padding-bottom: 0.25rem !important;
}

.pb-2 {
	padding-bottom: 0.5rem !important;
}

.pb-3 {
	padding-bottom: 1rem !important;
}

.pb-4 {
	padding-bottom: 1.5rem !important;
}

.pb-5 {
	padding-bottom: 3rem !important;
}
.ps-0 {
	padding-left: 0 !important;
}

.ps-1 {
	padding-left: 0.25rem !important;
}

.ps-2 {
	padding-left: 0.5rem !important;
}

.ps-3 {
	padding-left: 1rem !important;
}

.ps-4 {
	padding-left: 1.5rem !important;
}

.ps-5 {
	padding-left: 3rem !important;
}
.px-0 {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.px-1 {
	padding-right: 0.25rem !important;
	padding-left: 0.25rem !important;
}

.px-2 {
	padding-right: 0.5rem !important;
	padding-left: 0.5rem !important;
}

.px-3 {
	padding-right: 1rem !important;
	padding-left: 1rem !important;
}

.px-4 {
	padding-right: 1.5rem !important;
	padding-left: 1.5rem !important;
}

.px-5 {
	padding-right: 3rem !important;
	padding-left: 3rem !important;
}
.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.py-1 {
	padding-top: 0.25rem !important;
	padding-bottom: 0.25rem !important;
}

.py-2 {
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
}

.py-3 {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
}

.py-4 {
	padding-top: 1.5rem !important;
	padding-bottom: 1.5rem !important;
}

.py-5 {
	padding-top: 3rem !important;
	padding-bottom: 3rem !important;
}
.gap-0 {
	gap: 0 !important;
}

.gap-1 {
	gap: 0.25rem !important;
}

.gap-2 {
	gap: 0.5rem !important;
}

.gap-3 {
	gap: 1rem !important;
}

.gap-4 {
	gap: 1.5rem !important;
}

.gap-5 {
	gap: 3rem !important;
}
.row-gap-0 {
	row-gap: 0 !important;
}

.row-gap-1 {
	row-gap: 0.25rem !important;
}

.row-gap-2 {
	row-gap: 0.5rem !important;
}

.row-gap-3 {
	row-gap: 1rem !important;
}

.row-gap-4 {
	row-gap: 1.5rem !important;
}

.row-gap-5 {
	row-gap: 3rem !important;
}
.column-gap-0 {
	-moz-column-gap: 0 !important;
	column-gap: 0 !important;
}

.column-gap-1 {
	-moz-column-gap: 0.25rem !important;
	column-gap: 0.25rem !important;
}

.column-gap-2 {
	-moz-column-gap: 0.5rem !important;
	column-gap: 0.5rem !important;
}

.column-gap-3 {
	-moz-column-gap: 1rem !important;
	column-gap: 1rem !important;
}

.column-gap-4 {
	-moz-column-gap: 1.5rem !important;
	column-gap: 1.5rem !important;
}

.column-gap-5 {
	-moz-column-gap: 3rem !important;
	column-gap: 3rem !important;
}
.radius-1 {
	border-radius: 1px;
}

.radius-2 {
	border-radius: 2px;
}

.radius-3 {
	border-radius: 3px;
}

.radius-4 {
	border-radius: 4px;
}

.radius-5 {
	border-radius: 5px;
}

.radius-6 {
	border-radius: 6px;
}

.radius-7 {
	border-radius: 7px;
}

.radius-8 {
	border-radius: 8px;
}

.radius-9 {
	border-radius: 9px;
}

.radius-10 {
	border-radius: 10px;
}

.radius-50 {
	border-radius: 50%;
}
.fs-12 {
	font-size: 0.75rem;
}

.fs-13 {
	font-size: 0.813rem;
}

.fs-14 {
	font-size: 0.875rem;
}

.fs-15 {
	font-size: 0.938rem;
}

.fs-16 {
	font-size: 1rem;
}

.fs-17 {
	font-size: 1.063rem;
}

.fs-18 {
	font-size: 1.125rem;
}

.fs-19 {
	font-size: 1.188rem;
}

.fs-20 {
	font-size: 1.25rem;
}

.fs-21 {
	font-size: 1.313rem;
}

.fs-22 {
	font-size: 1.375rem;
}

.fs-23 {
	font-size: 1.438rem;
}

.fs-24 {
	font-size: 1.5rem;
}
.fw-normal {
	font-weight: 400 !important;
}

.fw-medium {
	font-weight: 500 !important;
}

.fw-semibold {
	font-weight: 600 !important;
}

.fw-bold {
	font-weight: 700 !important;
}

.fw-extrabold {
	font-weight: 800 !important;
}

.fw-black {
	font-weight: 900 !important;
}

.fst-italic {
	font-style: italic !important;
}
.text-start {
	text-align: left !important;
}

.text-end {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}
.text-decoration-none {
	text-decoration: none !important;
}

.text-decoration-underline {
	text-decoration: underline !important;
}

.text-decoration-line-through {
	text-decoration: line-through !important;
}
.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}
.text-wrap {
	white-space: normal !important;
}

.text-nowrap {
	white-space: nowrap !important;
}
.overflow-auto {
	overflow: auto !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.overflow-x-auto {
	overflow-x: auto !important;
}

.overflow-x-hidden {
	overflow-x: hidden !important;
}

.overflow-y-auto {
	overflow-y: auto !important;
}

.overflow-y-hidden {
	overflow-y: hidden !important;
}

.flex-fill {
 
  flex: 1 1 auto !important;
}
.flex-column {
  flex-direction: column !important;
}

 
 

.flex-column {
	flex-direction: column !important;
 
}
.dark-bg-one {
	background: #282a30;
}

.dark-bg-two {
	background: #24262b;
}

.dark-bg-three {
	background: #1e2024;
}

.dark-bg-four {
	background: #17181b;
}
.bg-danger {
	background-color: rgba(220, 53, 69) !important;
}

.bg-success {
	background-color: rgba(25, 135, 84) !important;
}

.bg-warning {
	background-color: rgba(255, 193, 7) !important;
}

.text-success {
 
  color: rgba(25, 135, 84) !important;
}

 
.text-white {
	color: var(--white) !important;
 
}
.fs-12 {
	font-size: 0.75rem;
}

.fs-13 {
	font-size: 0.813rem;
}

.fs-14 {
	font-size: 0.875rem;
}

.fs-15 {
	font-size: 0.938rem;
}

.fs-16 {
	font-size: 1rem;
}

.fs-17 {
	font-size: 1.063rem;
}

.fs-18 {
	font-size: 1.125rem;
}

.fs-19 {
	font-size: 1.188rem;
}

.fs-20 {
	font-size: 1.25rem;
}

.fs-21 {
	font-size: 1.313rem;
}

.fs-22 {
	font-size: 1.375rem;
}

.fs-23 {
	font-size: 1.438rem;
}

.fs-24 {
	font-size: 1.5rem;
}
.form-control {
	background: var(--dark-three);
	border: none;
	outline: none;
	box-shadow: none;
	color: var(--text-color-primary);
	padding: 1rem;
	border: solid 0.06rem var(--dark-three);
	width: 100%;
	border-radius: 4px;
}

.form-control:focus,
.form-control:active {
	background: var(--dark-three);
	border: none;
	outline: none;
	box-shadow: none;
	color: var(--text-color-primary);
	border: solid 0.06rem var(--dark-three);
}

.form-control::-moz-placeholder {
	color: var(--text-color-primary);
	opacity: 1;
}

.form-control::placeholder {
	color: var(--text-color-primary);
	opacity: 1;
}
:root {
	--white: #fff;
	--black: #000;
	--text-color-primary: #98a7b5;
	--text-color-secondary: #cfcfcf;
	--dark-one: #282a30;
	--dark-two: #24262b;
	--dark-three: #1e2024;
	--dark-four: #17181b;
}

.alert {
	padding: 0.8rem;
	border-radius: 4px;
}

.alert-success {
	background: #a3cfbb;
	color: #0a3622;
}
.alert-danger {
	background: #f1aeb5;
	color: #58151c;
}
.text-color-primary {
	color: var(--text-color-primary);
}

.text-color-secondary {
	color: var(--text-color-secondary);
}

/*/////////////////////////////////////////////////////////
RESPONSIVENESS
/////////////////////////////////////////////////////////*/
@media (max-width:575.98px) {
	.main-wrapper-container .hidden-xs-down {
		display: none !important;
	}
}

@media (min-width:576px) {
	.main-wrapper-container .hidden-sm-up {
		display: none !important;
	}
}

@media (max-width:767.98px) {
	.main-wrapper-container .hidden-sm-down {
		display: none !important;
	}
}

@media (min-width:768px) {
	.main-wrapper-container .hidden-md-up {
		display: none !important;
	}
}

@media (max-width:991.98px) {
	.main-wrapper-container .hidden-md-down {
		display: none !important;
	}
}

@media (min-width:992px) {
	.main-wrapper-container .hidden-lg-up {
		display: none !important;
	}
}

@media (max-width:1199.98px) {
	.main-wrapper-container .hidden-lg-down {
		display: none !important;
	}
}

@media (min-width:1200px) {
	.main-wrapper-container .hidden-xl-up {
		display: none !important;
	}
}

.main-wrapper-container .hidden-xl-down {
	display: none !important;
}

.main-wrapper-container .visible-print-block {
	display: none !important;
}

@media print {
	.main-wrapper-container .visible-print-block {
		display: block !important;
	}
}

.main-wrapper-container .visible-print-inline {
	display: none !important;
}

@media print {
	.main-wrapper-container .visible-print-inline {
		display: inline !important;
	}
}

.main-wrapper-container .visible-print-inline-block {
	display: none !important;
}

@media print {
	.main-wrapper-container .visible-print-inline-block {
		display: inline-block !important;
	}
}

@media print {
	.main-wrapper-container .hidden-print {
		display: none !important;
	}
}

.main-wrapper-container .container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

@media (min-width:576px) {
	.main-wrapper-container .container {
		max-width: 100%;
	}
}

@media (min-width:768px) {
	.main-wrapper-container .container {
		max-width: 100%;
	}
}

@media (min-width:992px) {
	.main-wrapper-container .container {
		max-width: 100%;
	}
}

@media (min-width:1200px) {
	.main-wrapper-container .container {
		max-width: 100%;
	}
}

.main-wrapper-container .container-fluid {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

.main-wrapper-container .row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}

.main-wrapper-container .no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.main-wrapper-container .no-gutters>.col,
.main-wrapper-container .no-gutters>[class*="col-"] {
	padding-right: 0;
	padding-left: 0;
}

.main-wrapper-container .col-1,
.main-wrapper-container .col-2,
.main-wrapper-container .col-3,
.main-wrapper-container .col-4,
.main-wrapper-container .col-5,
.main-wrapper-container .col-6,
.main-wrapper-container .col-7,
.main-wrapper-container .col-8,
.main-wrapper-container .col-9,
.main-wrapper-container .col-10,
.main-wrapper-container .col-11,
.main-wrapper-container .col-12,
.main-wrapper-container .col,
.main-wrapper-container .col-auto,
.main-wrapper-container .col-sm-1,
.main-wrapper-container .col-sm-2,
.main-wrapper-container .col-sm-3,
.main-wrapper-container .col-sm-4,
.main-wrapper-container .col-sm-5,
.main-wrapper-container .col-sm-6,
.main-wrapper-container .col-sm-7,
.main-wrapper-container .col-sm-8,
.main-wrapper-container .col-sm-9,
.main-wrapper-container .col-sm-10,
.main-wrapper-container .col-sm-11,
.main-wrapper-container .col-sm-12,
.main-wrapper-container .col-sm,
.main-wrapper-container .col-sm-auto,
.main-wrapper-container .col-md-1,
.main-wrapper-container .col-md-2,
.main-wrapper-container .col-md-3,
.main-wrapper-container .col-md-4,
.main-wrapper-container .col-md-5,
.main-wrapper-container .col-md-6,
.main-wrapper-container .col-md-7,
.main-wrapper-container .col-md-8,
.main-wrapper-container .col-md-9,
.main-wrapper-container .col-md-10,
.main-wrapper-container .col-md-11,
.main-wrapper-container .col-md-12,
.main-wrapper-container .col-md,
.main-wrapper-container .col-md-auto,
.main-wrapper-container .col-lg-1,
.main-wrapper-container .col-lg-2,
.main-wrapper-container .col-lg-3,
.main-wrapper-container .col-lg-4,
.main-wrapper-container .col-lg-5,
.main-wrapper-container .col-lg-6,
.main-wrapper-container .col-lg-7,
.main-wrapper-container .col-lg-8,
.main-wrapper-container .col-lg-9,
.main-wrapper-container .col-lg-10,
.main-wrapper-container .col-lg-11,
.main-wrapper-container .col-lg-12,
.main-wrapper-container .col-lg,
.main-wrapper-container .col-lg-auto,
.main-wrapper-container .col-xl-1,
.main-wrapper-container .col-xl-2,
.main-wrapper-container .col-xl-3,
.main-wrapper-container .col-xl-4,
.main-wrapper-container .col-xl-5,
.main-wrapper-container .col-xl-6,
.main-wrapper-container .col-xl-7,
.main-wrapper-container .col-xl-8,
.main-wrapper-container .col-xl-9,
.main-wrapper-container .col-xl-10,
.main-wrapper-container .col-xl-11,
.main-wrapper-container .col-xl-12,
.main-wrapper-container .col-xl,
.main-wrapper-container .col-xl-auto {
	position: relative;
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
}

.main-wrapper-container .col {
	-webkit-flex-basis: 0;
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
}

.main-wrapper-container .col-auto {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

.main-wrapper-container .col-1 {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 8.3333333333%;
	-ms-flex: 0 0 8.3333333333%;
	flex: 0 0 8.3333333333%;
	max-width: 8.3333333333%;
}

.main-wrapper-container .col-2 {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 16.6666666667%;
	-ms-flex: 0 0 16.6666666667%;
	flex: 0 0 16.6666666667%;
	max-width: 16.6666666667%;
}

.main-wrapper-container .col-3 {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 25%;
	-ms-flex: 0 0 25%;
	flex: 0 0 25%;
	max-width: 25%;
}

.main-wrapper-container .col-4 {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 33.3333333333%;
	-ms-flex: 0 0 33.3333333333%;
	flex: 0 0 33.3333333333%;
	max-width: 33.3333333333%;
}

.main-wrapper-container .col-5 {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 41.6666666667%;
	-ms-flex: 0 0 41.6666666667%;
	flex: 0 0 41.6666666667%;
	max-width: 41.6666666667%;
}

.main-wrapper-container .col-6 {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 50%;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	max-width: 50%;
}

.main-wrapper-container .col-7 {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 58.3333333333%;
	-ms-flex: 0 0 58.3333333333%;
	flex: 0 0 58.3333333333%;
	max-width: 58.3333333333%;
}

.main-wrapper-container .col-8 {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 66.6666666667%;
	-ms-flex: 0 0 66.6666666667%;
	flex: 0 0 66.6666666667%;
	max-width: 66.6666666667%;
}

.main-wrapper-container .col-9 {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 75%;
	-ms-flex: 0 0 75%;
	flex: 0 0 75%;
	max-width: 75%;
}

.main-wrapper-container .col-10 {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 83.3333333333%;
	-ms-flex: 0 0 83.3333333333%;
	flex: 0 0 83.3333333333%;
	max-width: 83.3333333333%;
}

.main-wrapper-container .col-11 {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 91.6666666667%;
	-ms-flex: 0 0 91.6666666667%;
	flex: 0 0 91.6666666667%;
	max-width: 91.6666666667%;
}

.main-wrapper-container .col-12 {
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 100%;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;
}

.main-wrapper-container .order-first {
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
}

.main-wrapper-container .order-last {
	-webkit-box-ordinal-group: 14;
	-webkit-order: 13;
	-ms-flex-order: 13;
	order: 13;
}

.main-wrapper-container .order-0 {
	-webkit-box-ordinal-group: 1;
	-webkit-order: 0;
	-ms-flex-order: 0;
	order: 0;
}

.main-wrapper-container .order-1 {
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.main-wrapper-container .order-2 {
	-webkit-box-ordinal-group: 3;
	-webkit-order: 2;
	-ms-flex-order: 2;
	order: 2;
}

.main-wrapper-container .order-3 {
	-webkit-box-ordinal-group: 4;
	-webkit-order: 3;
	-ms-flex-order: 3;
	order: 3;
}

.main-wrapper-container .order-4 {
	-webkit-box-ordinal-group: 5;
	-webkit-order: 4;
	-ms-flex-order: 4;
	order: 4;
}

.main-wrapper-container .order-5 {
	-webkit-box-ordinal-group: 6;
	-webkit-order: 5;
	-ms-flex-order: 5;
	order: 5;
}

.main-wrapper-container .order-6 {
	-webkit-box-ordinal-group: 7;
	-webkit-order: 6;
	-ms-flex-order: 6;
	order: 6;
}

.main-wrapper-container .order-7 {
	-webkit-box-ordinal-group: 8;
	-webkit-order: 7;
	-ms-flex-order: 7;
	order: 7;
}

.main-wrapper-container .order-8 {
	-webkit-box-ordinal-group: 9;
	-webkit-order: 8;
	-ms-flex-order: 8;
	order: 8;
}

.main-wrapper-container .order-9 {
	-webkit-box-ordinal-group: 10;
	-webkit-order: 9;
	-ms-flex-order: 9;
	order: 9;
}

.main-wrapper-container .order-10 {
	-webkit-box-ordinal-group: 11;
	-webkit-order: 10;
	-ms-flex-order: 10;
	order: 10;
}

.main-wrapper-container .order-11 {
	-webkit-box-ordinal-group: 12;
	-webkit-order: 11;
	-ms-flex-order: 11;
	order: 11;
}

.main-wrapper-container .order-12 {
	-webkit-box-ordinal-group: 13;
	-webkit-order: 12;
	-ms-flex-order: 12;
	order: 12;
}

.main-wrapper-container .offset-1 {
	margin-left: 8.3333333333%;
}

.main-wrapper-container .offset-2 {
	margin-left: 16.6666666667%;
}

.main-wrapper-container .offset-3 {
	margin-left: 25%;
}

.main-wrapper-container .offset-4 {
	margin-left: 33.3333333333%;
}

.main-wrapper-container .offset-5 {
	margin-left: 41.6666666667%;
}

.main-wrapper-container .offset-6 {
	margin-left: 50%;
}

.main-wrapper-container .offset-7 {
	margin-left: 58.3333333333%;
}

.main-wrapper-container .offset-8 {
	margin-left: 66.6666666667%;
}

.main-wrapper-container .offset-9 {
	margin-left: 75%;
}

.main-wrapper-container .offset-10 {
	margin-left: 83.3333333333%;
}

.main-wrapper-container .offset-11 {
	margin-left: 91.6666666667%;
}

.small-logo {
	display: none;
}

.expanded-spin-img-container img {
	max-width: 70%;
}
.hidden-bar {
	transition: 0.3s;
	opacity: 0;
	visibility: hidden;
	left: -150px;
}

.shown-bar {
	transition: 0.3s;
	left: 0px;
	opacity: 100%;
}
.mobile-chatBox-button-container-main {
	visibility: hidden;
}
.tasks-button img {
	max-width: 50%;
}

.mobile-nav-item-name-container {
	display: none;
}

.side-bar-colored-action-button svg {
	display: none;
}

.rounded-wallet-on-mobile-nav {
	display: none;
}

@media (min-width:576px) {
	.main-wrapper-container .col-sm {
		-webkit-flex-basis: 0;
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}

	.main-wrapper-container .col-sm-auto {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}

	.main-wrapper-container .col-sm-1 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 8.3333333333%;
		-ms-flex: 0 0 8.3333333333%;
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
	}

	.main-wrapper-container .col-sm-2 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 16.6666666667%;
		-ms-flex: 0 0 16.6666666667%;
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}

	.main-wrapper-container .col-sm-3 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 25%;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.main-wrapper-container .col-sm-4 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 33.3333333333%;
		-ms-flex: 0 0 33.3333333333%;
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}

	.main-wrapper-container .col-sm-5 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 41.6666666667%;
		-ms-flex: 0 0 41.6666666667%;
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
	}

	.main-wrapper-container .col-sm-6 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 50%;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.main-wrapper-container .col-sm-7 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 58.3333333333%;
		-ms-flex: 0 0 58.3333333333%;
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
	}

	.main-wrapper-container .col-sm-8 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 66.6666666667%;
		-ms-flex: 0 0 66.6666666667%;
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}

	.main-wrapper-container .col-sm-9 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 75%;
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.main-wrapper-container .col-sm-10 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 83.3333333333%;
		-ms-flex: 0 0 83.3333333333%;
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
	}

	.main-wrapper-container .col-sm-11 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 91.6666666667%;
		-ms-flex: 0 0 91.6666666667%;
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
	}

	.main-wrapper-container .col-sm-12 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 100%;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.main-wrapper-container .order-sm-first {
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.main-wrapper-container .order-sm-last {
		-webkit-box-ordinal-group: 14;
		-webkit-order: 13;
		-ms-flex-order: 13;
		order: 13;
	}

	.main-wrapper-container .order-sm-0 {
		-webkit-box-ordinal-group: 1;
		-webkit-order: 0;
		-ms-flex-order: 0;
		order: 0;
	}

	.main-wrapper-container .order-sm-1 {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.main-wrapper-container .order-sm-2 {
		-webkit-box-ordinal-group: 3;
		-webkit-order: 2;
		-ms-flex-order: 2;
		order: 2;
	}

	.main-wrapper-container .order-sm-3 {
		-webkit-box-ordinal-group: 4;
		-webkit-order: 3;
		-ms-flex-order: 3;
		order: 3;
	}

	.main-wrapper-container .order-sm-4 {
		-webkit-box-ordinal-group: 5;
		-webkit-order: 4;
		-ms-flex-order: 4;
		order: 4;
	}

	.main-wrapper-container .order-sm-5 {
		-webkit-box-ordinal-group: 6;
		-webkit-order: 5;
		-ms-flex-order: 5;
		order: 5;
	}

	.main-wrapper-container .order-sm-6 {
		-webkit-box-ordinal-group: 7;
		-webkit-order: 6;
		-ms-flex-order: 6;
		order: 6;
	}

	.main-wrapper-container .order-sm-7 {
		-webkit-box-ordinal-group: 8;
		-webkit-order: 7;
		-ms-flex-order: 7;
		order: 7;
	}

	.main-wrapper-container .order-sm-8 {
		-webkit-box-ordinal-group: 9;
		-webkit-order: 8;
		-ms-flex-order: 8;
		order: 8;
	}

	.main-wrapper-container .order-sm-9 {
		-webkit-box-ordinal-group: 10;
		-webkit-order: 9;
		-ms-flex-order: 9;
		order: 9;
	}

	.main-wrapper-container .order-sm-10 {
		-webkit-box-ordinal-group: 11;
		-webkit-order: 10;
		-ms-flex-order: 10;
		order: 10;
	}

	.main-wrapper-container .order-sm-11 {
		-webkit-box-ordinal-group: 12;
		-webkit-order: 11;
		-ms-flex-order: 11;
		order: 11;
	}

	.main-wrapper-container .order-sm-12 {
		-webkit-box-ordinal-group: 13;
		-webkit-order: 12;
		-ms-flex-order: 12;
		order: 12;
	}

	.main-wrapper-container .offset-sm-0 {
		margin-left: 0;
	}

	.main-wrapper-container .offset-sm-1 {
		margin-left: 8.3333333333%;
	}

	.main-wrapper-container .offset-sm-2 {
		margin-left: 16.6666666667%;
	}

	.main-wrapper-container .offset-sm-3 {
		margin-left: 25%;
	}

	.main-wrapper-container .offset-sm-4 {
		margin-left: 33.3333333333%;
	}

	.main-wrapper-container .offset-sm-5 {
		margin-left: 41.6666666667%;
	}

	.main-wrapper-container .offset-sm-6 {
		margin-left: 50%;
	}

	.main-wrapper-container .offset-sm-7 {
		margin-left: 58.3333333333%;
	}

	.main-wrapper-container .offset-sm-8 {
		margin-left: 66.6666666667%;
	}

	.main-wrapper-container .offset-sm-9 {
		margin-left: 75%;
	}

	.main-wrapper-container .offset-sm-10 {
		margin-left: 83.3333333333%;
	}

	.main-wrapper-container .offset-sm-11 {
		margin-left: 91.6666666667%;
	}
}

@media (min-width:768px) {
	.main-wrapper-container .col-md {
		-webkit-flex-basis: 0;
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}

	.main-wrapper-container .col-md-auto {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}

	.main-wrapper-container .col-md-1 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 8.3333333333%;
		-ms-flex: 0 0 8.3333333333%;
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
	}

	.main-wrapper-container .col-md-2 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 16.6666666667%;
		-ms-flex: 0 0 16.6666666667%;
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}

	.main-wrapper-container .col-md-3 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 25%;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.main-wrapper-container .col-md-4 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 33.3333333333%;
		-ms-flex: 0 0 33.3333333333%;
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}

	.main-wrapper-container .col-md-5 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 41.6666666667%;
		-ms-flex: 0 0 41.6666666667%;
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
	}

	.main-wrapper-container .col-md-6 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 50%;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.main-wrapper-container .col-md-7 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 58.3333333333%;
		-ms-flex: 0 0 58.3333333333%;
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
	}

	.main-wrapper-container .col-md-8 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 66.6666666667%;
		-ms-flex: 0 0 66.6666666667%;
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}

	.main-wrapper-container .col-md-9 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 75%;
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.main-wrapper-container .col-md-10 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 83.3333333333%;
		-ms-flex: 0 0 83.3333333333%;
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
	}

	.main-wrapper-container .col-md-11 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 91.6666666667%;
		-ms-flex: 0 0 91.6666666667%;
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
	}

	.main-wrapper-container .col-md-12 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 100%;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.main-wrapper-container .order-md-first {
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.main-wrapper-container .order-md-last {
		-webkit-box-ordinal-group: 14;
		-webkit-order: 13;
		-ms-flex-order: 13;
		order: 13;
	}

	.main-wrapper-container .order-md-0 {
		-webkit-box-ordinal-group: 1;
		-webkit-order: 0;
		-ms-flex-order: 0;
		order: 0;
	}

	.main-wrapper-container .order-md-1 {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.main-wrapper-container .order-md-2 {
		-webkit-box-ordinal-group: 3;
		-webkit-order: 2;
		-ms-flex-order: 2;
		order: 2;
	}

	.main-wrapper-container .order-md-3 {
		-webkit-box-ordinal-group: 4;
		-webkit-order: 3;
		-ms-flex-order: 3;
		order: 3;
	}

	.main-wrapper-container .order-md-4 {
		-webkit-box-ordinal-group: 5;
		-webkit-order: 4;
		-ms-flex-order: 4;
		order: 4;
	}

	.main-wrapper-container .order-md-5 {
		-webkit-box-ordinal-group: 6;
		-webkit-order: 5;
		-ms-flex-order: 5;
		order: 5;
	}

	.main-wrapper-container .order-md-6 {
		-webkit-box-ordinal-group: 7;
		-webkit-order: 6;
		-ms-flex-order: 6;
		order: 6;
	}

	.main-wrapper-container .order-md-7 {
		-webkit-box-ordinal-group: 8;
		-webkit-order: 7;
		-ms-flex-order: 7;
		order: 7;
	}

	.main-wrapper-container .order-md-8 {
		-webkit-box-ordinal-group: 9;
		-webkit-order: 8;
		-ms-flex-order: 8;
		order: 8;
	}

	.main-wrapper-container .order-md-9 {
		-webkit-box-ordinal-group: 10;
		-webkit-order: 9;
		-ms-flex-order: 9;
		order: 9;
	}

	.main-wrapper-container .order-md-10 {
		-webkit-box-ordinal-group: 11;
		-webkit-order: 10;
		-ms-flex-order: 10;
		order: 10;
	}

	.main-wrapper-container .order-md-11 {
		-webkit-box-ordinal-group: 12;
		-webkit-order: 11;
		-ms-flex-order: 11;
		order: 11;
	}

	.main-wrapper-container .order-md-12 {
		-webkit-box-ordinal-group: 13;
		-webkit-order: 12;
		-ms-flex-order: 12;
		order: 12;
	}

	.main-wrapper-container .offset-md-0 {
		margin-left: 0;
	}

	.main-wrapper-container .offset-md-1 {
		margin-left: 8.3333333333%;
	}

	.main-wrapper-container .offset-md-2 {
		margin-left: 16.6666666667%;
	}

	.main-wrapper-container .offset-md-3 {
		margin-left: 25%;
	}

	.main-wrapper-container .offset-md-4 {
		margin-left: 33.3333333333%;
	}

	.main-wrapper-container .offset-md-5 {
		margin-left: 41.6666666667%;
	}

	.main-wrapper-container .offset-md-6 {
		margin-left: 50%;
	}

	.main-wrapper-container .offset-md-7 {
		margin-left: 58.3333333333%;
	}

	.main-wrapper-container .offset-md-8 {
		margin-left: 66.6666666667%;
	}

	.main-wrapper-container .offset-md-9 {
		margin-left: 75%;
	}

	.main-wrapper-container .offset-md-10 {
		margin-left: 83.3333333333%;
	}

	.main-wrapper-container .offset-md-11 {
		margin-left: 91.6666666667%;
	}
}

@media (min-width:992px) {
	.main-wrapper-container .col-lg {
		-webkit-flex-basis: 0;
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}

	.main-wrapper-container .col-lg-auto {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}

	.main-wrapper-container .col-lg-1 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 8.3333333333%;
		-ms-flex: 0 0 8.3333333333%;
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
	}

	.main-wrapper-container .col-lg-2 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 16.6666666667%;
		-ms-flex: 0 0 16.6666666667%;
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}

	.main-wrapper-container .col-lg-3 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 25%;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.main-wrapper-container .col-lg-4 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 33.3333333333%;
		-ms-flex: 0 0 33.3333333333%;
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}

	.main-wrapper-container .col-lg-5 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 41.6666666667%;
		-ms-flex: 0 0 41.6666666667%;
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
	}

	.main-wrapper-container .col-lg-6 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 50%;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.main-wrapper-container .col-lg-7 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 58.3333333333%;
		-ms-flex: 0 0 58.3333333333%;
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
	}

	.main-wrapper-container .col-lg-8 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 66.6666666667%;
		-ms-flex: 0 0 66.6666666667%;
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}

	.main-wrapper-container .col-lg-9 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 75%;
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.main-wrapper-container .col-lg-10 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 83.3333333333%;
		-ms-flex: 0 0 83.3333333333%;
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
	}

	.main-wrapper-container .col-lg-11 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 91.6666666667%;
		-ms-flex: 0 0 91.6666666667%;
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
	}

	.main-wrapper-container .col-lg-12 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 100%;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.main-wrapper-container .order-lg-first {
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.main-wrapper-container .order-lg-last {
		-webkit-box-ordinal-group: 14;
		-webkit-order: 13;
		-ms-flex-order: 13;
		order: 13;
	}

	.main-wrapper-container .order-lg-0 {
		-webkit-box-ordinal-group: 1;
		-webkit-order: 0;
		-ms-flex-order: 0;
		order: 0;
	}

	.main-wrapper-container .order-lg-1 {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.main-wrapper-container .order-lg-2 {
		-webkit-box-ordinal-group: 3;
		-webkit-order: 2;
		-ms-flex-order: 2;
		order: 2;
	}

	.main-wrapper-container .order-lg-3 {
		-webkit-box-ordinal-group: 4;
		-webkit-order: 3;
		-ms-flex-order: 3;
		order: 3;
	}

	.main-wrapper-container .order-lg-4 {
		-webkit-box-ordinal-group: 5;
		-webkit-order: 4;
		-ms-flex-order: 4;
		order: 4;
	}

	.main-wrapper-container .order-lg-5 {
		-webkit-box-ordinal-group: 6;
		-webkit-order: 5;
		-ms-flex-order: 5;
		order: 5;
	}

	.main-wrapper-container .order-lg-6 {
		-webkit-box-ordinal-group: 7;
		-webkit-order: 6;
		-ms-flex-order: 6;
		order: 6;
	}

	.main-wrapper-container .order-lg-7 {
		-webkit-box-ordinal-group: 8;
		-webkit-order: 7;
		-ms-flex-order: 7;
		order: 7;
	}

	.main-wrapper-container .order-lg-8 {
		-webkit-box-ordinal-group: 9;
		-webkit-order: 8;
		-ms-flex-order: 8;
		order: 8;
	}

	.main-wrapper-container .order-lg-9 {
		-webkit-box-ordinal-group: 10;
		-webkit-order: 9;
		-ms-flex-order: 9;
		order: 9;
	}

	.main-wrapper-container .order-lg-10 {
		-webkit-box-ordinal-group: 11;
		-webkit-order: 10;
		-ms-flex-order: 10;
		order: 10;
	}

	.main-wrapper-container .order-lg-11 {
		-webkit-box-ordinal-group: 12;
		-webkit-order: 11;
		-ms-flex-order: 11;
		order: 11;
	}

	.main-wrapper-container .order-lg-12 {
		-webkit-box-ordinal-group: 13;
		-webkit-order: 12;
		-ms-flex-order: 12;
		order: 12;
	}

	.main-wrapper-container .offset-lg-0 {
		margin-left: 0;
	}

	.main-wrapper-container .offset-lg-1 {
		margin-left: 8.3333333333%;
	}

	.main-wrapper-container .offset-lg-2 {
		margin-left: 16.6666666667%;
	}

	.main-wrapper-container .offset-lg-3 {
		margin-left: 25%;
	}

	.main-wrapper-container .offset-lg-4 {
		margin-left: 33.3333333333%;
	}

	.main-wrapper-container .offset-lg-5 {
		margin-left: 41.6666666667%;
	}

	.main-wrapper-container .offset-lg-6 {
		margin-left: 50%;
	}

	.main-wrapper-container .offset-lg-7 {
		margin-left: 58.3333333333%;
	}

	.main-wrapper-container .offset-lg-8 {
		margin-left: 66.6666666667%;
	}

	.main-wrapper-container .offset-lg-9 {
		margin-left: 75%;
	}

	.main-wrapper-container .offset-lg-10 {
		margin-left: 83.3333333333%;
	}

	.main-wrapper-container .offset-lg-11 {
		margin-left: 91.6666666667%;
	}
}

@media (min-width:1200px) {
	.main-wrapper-container .col-xl {
		-webkit-flex-basis: 0;
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		max-width: 100%;
	}

	.main-wrapper-container .col-xl-auto {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
	}

	.main-wrapper-container .col-xl-1 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 8.3333333333%;
		-ms-flex: 0 0 8.3333333333%;
		flex: 0 0 8.3333333333%;
		max-width: 8.3333333333%;
	}

	.main-wrapper-container .col-xl-2 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 16.6666666667%;
		-ms-flex: 0 0 16.6666666667%;
		flex: 0 0 16.6666666667%;
		max-width: 16.6666666667%;
	}

	.main-wrapper-container .col-xl-3 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 25%;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.main-wrapper-container .col-xl-4 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 33.3333333333%;
		-ms-flex: 0 0 33.3333333333%;
		flex: 0 0 33.3333333333%;
		max-width: 33.3333333333%;
	}

	.main-wrapper-container .col-xl-5 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 41.6666666667%;
		-ms-flex: 0 0 41.6666666667%;
		flex: 0 0 41.6666666667%;
		max-width: 41.6666666667%;
	}

	.main-wrapper-container .col-xl-6 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 50%;
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.main-wrapper-container .col-xl-7 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 58.3333333333%;
		-ms-flex: 0 0 58.3333333333%;
		flex: 0 0 58.3333333333%;
		max-width: 58.3333333333%;
	}

	.main-wrapper-container .col-xl-8 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 66.6666666667%;
		-ms-flex: 0 0 66.6666666667%;
		flex: 0 0 66.6666666667%;
		max-width: 66.6666666667%;
	}

	.main-wrapper-container .col-xl-9 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 75%;
		-ms-flex: 0 0 75%;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.main-wrapper-container .col-xl-10 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 83.3333333333%;
		-ms-flex: 0 0 83.3333333333%;
		flex: 0 0 83.3333333333%;
		max-width: 83.3333333333%;
	}

	.main-wrapper-container .col-xl-11 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 91.6666666667%;
		-ms-flex: 0 0 91.6666666667%;
		flex: 0 0 91.6666666667%;
		max-width: 91.6666666667%;
	}

	.main-wrapper-container .col-xl-12 {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 100%;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.main-wrapper-container .order-xl-first {
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
	}

	.main-wrapper-container .order-xl-last {
		-webkit-box-ordinal-group: 14;
		-webkit-order: 13;
		-ms-flex-order: 13;
		order: 13;
	}

	.main-wrapper-container .order-xl-0 {
		-webkit-box-ordinal-group: 1;
		-webkit-order: 0;
		-ms-flex-order: 0;
		order: 0;
	}

	.main-wrapper-container .order-xl-1 {
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.main-wrapper-container .order-xl-2 {
		-webkit-box-ordinal-group: 3;
		-webkit-order: 2;
		-ms-flex-order: 2;
		order: 2;
	}

	.main-wrapper-container .order-xl-3 {
		-webkit-box-ordinal-group: 4;
		-webkit-order: 3;
		-ms-flex-order: 3;
		order: 3;
	}

	.main-wrapper-container .order-xl-4 {
		-webkit-box-ordinal-group: 5;
		-webkit-order: 4;
		-ms-flex-order: 4;
		order: 4;
	}

	.main-wrapper-container .order-xl-5 {
		-webkit-box-ordinal-group: 6;
		-webkit-order: 5;
		-ms-flex-order: 5;
		order: 5;
	}

	.main-wrapper-container .order-xl-6 {
		-webkit-box-ordinal-group: 7;
		-webkit-order: 6;
		-ms-flex-order: 6;
		order: 6;
	}

	.main-wrapper-container .order-xl-7 {
		-webkit-box-ordinal-group: 8;
		-webkit-order: 7;
		-ms-flex-order: 7;
		order: 7;
	}

	.main-wrapper-container .order-xl-8 {
		-webkit-box-ordinal-group: 9;
		-webkit-order: 8;
		-ms-flex-order: 8;
		order: 8;
	}

	.main-wrapper-container .order-xl-9 {
		-webkit-box-ordinal-group: 10;
		-webkit-order: 9;
		-ms-flex-order: 9;
		order: 9;
	}

	.main-wrapper-container .order-xl-10 {
		-webkit-box-ordinal-group: 11;
		-webkit-order: 10;
		-ms-flex-order: 10;
		order: 10;
	}

	.main-wrapper-container .order-xl-11 {
		-webkit-box-ordinal-group: 12;
		-webkit-order: 11;
		-ms-flex-order: 11;
		order: 11;
	}

	.main-wrapper-container .order-xl-12 {
		-webkit-box-ordinal-group: 13;
		-webkit-order: 12;
		-ms-flex-order: 12;
		order: 12;
	}

	.main-wrapper-container .offset-xl-0 {
		margin-left: 0;
	}

	.main-wrapper-container .offset-xl-1 {
		margin-left: 8.3333333333%;
	}

	.main-wrapper-container .offset-xl-2 {
		margin-left: 16.6666666667%;
	}

	.main-wrapper-container .offset-xl-3 {
		margin-left: 25%;
	}

	.main-wrapper-container .offset-xl-4 {
		margin-left: 33.3333333333%;
	}

	.main-wrapper-container .offset-xl-5 {
		margin-left: 41.6666666667%;
	}

	.main-wrapper-container .offset-xl-6 {
		margin-left: 50%;
	}

	.main-wrapper-container .offset-xl-7 {
		margin-left: 58.3333333333%;
	}

	.main-wrapper-container .offset-xl-8 {
		margin-left: 66.6666666667%;
	}

	.main-wrapper-container .offset-xl-9 {
		margin-left: 75%;
	}

	.main-wrapper-container .offset-xl-10 {
		margin-left: 83.3333333333%;
	}

	.main-wrapper-container .offset-xl-11 {
		margin-left: 91.6666666667%;
	}
}

.main-wrapper-container .img-fluid {
	max-width: 100%;
	height: auto;
}
.multiplier-left-digits {
	width: 88px;
	display: inline-block;
	direction: rtl;
	white-space: nowrap;
	text-align: right;
	font-family: "Source Sans 3", sans-serif;
}

.multiplier-right-digits {
	width: 88px;
	display: inline-block;
	white-space: nowrap;
	text-align: left;
	font-family: "Source Sans 3", sans-serif;
}
 
@media (max-width: 1150px) {
  .betchart-class .graph.relative {
    width: calc(100% - 50px) !important;
    height: 260px !important;
    left: 10px;
  }
  .betchart-class.my_row.justify.relative.white {
    padding: 0px 0px 30px 0px;
  }
  .ref {
    width: 450px;
  }
}
@media (max-width: 1000px) {
  .betchart-class .graph.relative {
    height: 222px !important;
  }
  .ref {
    width: 420px;
  }
}
@media (max-width: 970px) {
  .common-footer-container {
    padding: 0 !important;
  }
  .ref {
    width: 420px !important;
  }
  .footer-content-container {
    display: block;
    text-align: center;
  }
  .accepted-networks-main-container .accepted_networks span {
    text-align: center !important;
  }
  .footer-content-container > div {
    width: 100% !important;
    padding: 20px 20px;
  }
}
@media (max-width: 950px) {
  .betchart-class .graph.relative {
    height: 210px !important;
  }
  .ref {
    width: 420px !important;
  }
}
@media (min-width: 901px) {
  .top-level-div {
    right: -5%;
  }
}
@media (max-width: 900px) {
  #chat-widget-container {
    bottom: 45px !important;
  }
  .menu-items-on-mobile {
    line-height: 0px;
  }
  .wallet-content-class {
    display: none;
  }
  .mob-nav-wallet-icon-container-outer {
    padding: 3px;
    background-color: #1e2024;
    border-radius: 50%;
  }
  .mob-nav-wallet-icon-container {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  button.rounded-wallet-on-mobile-nav.white.justified.column {
    display: flex;
    background: #1e2024;
    border-radius: 50%;
    position: relative;
    bottom: 10px;
  }
  .mob-nav-wallet-icon-container svg {
    font-size: 20px;
  }
  .side-bar-colored-action-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .additionl-mt-3 {
    margin-top: 7px !important;
  }
  .mobile-nav-item-name-container {
    margin-top: 3px;
    display: block;
    font-weight: 500;
  }
  .mobile-nav-item-name-container-wallet-capsule {
    margin-top: 0px !important;
  }
  .nav-item-text-mobile {
    font-size: 8px;
  }
  .side-bar-colored-action-button img {
    display: none;
  }
  .side-bar-colored-action-button svg {
    display: block;
  }
  .sidebar-action-button {
    background-color: transparent;
  }
  .side-bar-navigation-actions {
    display: none;
  }
  .side-bar-navigation-action-button {
    display: none;
  }
  .menu-items-on-mobile button {
    font-size: 8px;
  }
  .menu-items-on-mobile button svg {
    font-size: 8px;
  }
  .font-14-on-big-screen {
    font-size: 14px !important;
  }
  .sideBar-container {
    height: 60px;
    padding: 5px 0;
  }
  .top-level-div {
    width: 100%;
  }
  .sideBar-container.width-5.fixed.height {
    bottom: 0;
    width: 100%;
  }
  .sidebar-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 0 10px;
  }
  .side-bar-open-icon-container.width.justified,
  .mobile-chatBox-button-container-main {
    width: 20%;
  }
  .side-bar-navigation-actions-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin-top: 0px;
    gap: 0px;
  }
  .side-bar-colored-navigation-actions {
    gap: 0;
  }
  .side-bar-colored-navigation-actions.justified.column.relative {
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }
  .side-bar-navigation-actions.justified.column {
    flex-direction: row;
    gap: 10px;
  }
  .sideBar-container {
    border-right-color: #1e2024;
  }
  .hidden-bar {
    left: 0px;
  }
  .mobile-hidden-bar {
    transition: 0.3s;
    bottom: -150px !important;
    opacity: 0;
    visibility: hidden;
  }
  .side-bar-navigation-bottom-actions.justified.column {
    flex-direction: row;
  }

  /* .side-bar-colored-action-button img {
  max-width: 70%;
} */
  .common-game-container {
    margin-top: 15px;
  }
  .crash-game-content-container {
    display: block;
  }
  .Crash-graph-container {
    width: auto;
  }
  .crash-data-container {
    width: auto;
  }
  .betchart-class .graph.relative {
    height: 345px !important;
  }
  .betchart-class .seconds-distributor {
    width: 100% !important;
  }
  .betchart-class .timer-x-axis {
    width: 100% !important;
  }
  .betchart-class .multiplier-yAxis {
    height: 100% !important;
  }
  .mobile-chatBox-button-container-main {
    visibility: visible;
  }
}
@media (max-width: 800px) {
  .betchart-class .graph.relative {
    height: 305px !important;
  }
  .ref {
    width: 380px !important;
  }
}
@media (max-width: 780px) {
  .header-inner-div {
    padding: 6px 0;
  }
  .header-logo-container {
    text-align: center;
  }
  img.Logo-img {
    display: none;
  }
  .Mogile-Logo-img {
    max-height: 40px;
    margin: auto;
    margin-bottom: 5px;
    display: inline-block;
    width: auto;
  }
  .mascot-Logo-img {
    max-height: 40px;
    margin: auto;
    display: inline-block;
    width: auto;
  }
  .userFeedback-content-container {
    display: block;
    padding: 20px 0 1px 0 !important;
  }
  .userFeedback-content-container .userFeedback-typo-container {
    width: auto;
    padding: 0 20px;
  }
  .userFeedback-content-container .userFeedback-form-container {
    width: auto;
    padding: 0 20px;
  }
  .userProfileView {
    margin: -6px 10px -6px 0px !important;
  }
}
@media (max-width: 767px) {
  .large-logo {
    display: none;
  }
  .small-logo {
    display: block;
  }
  .referal-popup-img {
    height: 100px;
    width: auto !important;
  }
  .affiliate-search {
    padding: 15px 15px 5px 15px;
  }
  .box-1-content {
    text-align: center;
    margin: 0;
  }
  .box-1-content h1 {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .box-1-content h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }
  .box-2-content {
    margin: 0;
    text-align: center;
  }
  .signup-spin-main-containr {
    transform: scale(0.7);
  }
}
@media (max-width: 720px) {
  .hide-on-720px {
    display: none;
  }
  .my-bet-history-item *,
  .my-history-content {
    font-size: 11px !important;
  }
  .stats-main-div {
    padding: 10px;
  }
  .history-myHistory-data-container {
    padding: 0 !important;
  }
  .myBetsData-id-price-container {
    width: 38% !important;
  }
  .myBetsData-payout-profit-container {
    width: 37% !important;
  }
  .mybets-payout-profit-container {
    width: 37% !important;
  }
}
@media (max-width: 719px) {
  .betit-price-container {
    margin-top: 20px;
  }
  .betPrice-content-class {
    gap: 20px !important;
    justify-content: normal !important;
    align-items: normal !important;
    display: block;
    padding: 0 15px;
  }
  .betPrice-ManulBet-container {
    margin-bottom: 20px;
  }
  .ModalInps {
    width: 100%;
  }
  input.betAmmountField {
    width: 100%;
  }
  .bet-ammount-picker {
    flex-grow: 1;
  }
}
@media (max-width: 650px) {
  .betchart-class .graph.relative {
    height: 240px !important;
  }
  .ref {
    width: 380px !important;
  }
}
@media (max-width: 630px) {
  .desktopTrendsButton {
    display: none !important;
  }
  .header-lang-selector {
    display: none;
  }
  .chat-button-container {
    display: none;
  }
  .spin-button-container {
    margin-left: 4px !important;
  }
  .spin-button-container > button {
    padding: 5px 0 !important;
  }
  .spin-button-container > button .absolute {
    left: 9px !important;
  }
  .profile-typo {
    margin: 0 2px 0 1px !important;
  }
  .profile-typo-innner {
    display: none;
  }
  .header-top-profile-pic {
    margin-left: 4px !important;
  }
  .wallet-content-class button {
    padding: 13px 2px 14px 2px !important;
  }
  .wallet-content-class button svg {
    width: 16px;
    margin-right: 0 !important;
  }
  .userBetCurrency-main-container > button {
    font-size: 10px;
  }
  .actions-signup-container {
    margin-right: 0;
  }
  .actions-signin-container .headerAction-buttons {
    padding-left: 6px;
    padding-right: 6px;
  }
  .actions-signup-container .primary-btn {
    padding-left: 6px;
    padding-right: 6px;
  }
  .bankroll-container {
    width: 130px !important;
  }
  .trends-main-container {
    display: none;
  }
  .common-game-container {
    margin-top: 10px;
  }
  .trends-chat-on-mobile {
    display: flex !important;
    justify-content: end;
  }
  .trends-popup-design {
    transform: translate(0, -71px) !important;
  }
  .chat-box-container {
    top: 0 !important;
  }
  .userProfileView {
    margin-right: 0 !important;
    margin-left: 3px !important;
  }
}
@media (max-width: 600px) {
  .betchart-class .graph.relative {
    height: 220px !important;
  }
  .ref {
    width: 380px !important;
  }
  .wallet-inner-tabs-main {
    width: 500px !important;
    height: 500px !important;
  }
  .css-19kzrtu {
    padding: 0 0 20px 0 !important;
  }
  .wallet-qr-typo-container {
    width: 190px;
  }
  .qr-hash-container {
    font-size: 14px;
  }
  .wallet-qr-qr-code-container {
    margin-bottom: auto;
  }
  .wallet-crypto-coins {
    justify-content: space-between;
    margin: 20px 0px;
    overflow-x: scroll;
    flex-wrap: nowrap !important;
    gap: 10px;
    padding-bottom: 10px;
  }
  .wallet-crypto-coins {
    gap: 3px;
    margin-bottom: 0;
  }
  .wallet-crypto-coins > div {
    padding: 10px 6px !important;
    gap: 5px !important;
    font-size: 14px;
    flex-grow: 1;
    justify-content: center;
  }
  .wallet-crypto-coins > div .coin-img-container img {
    max-width: 16px;
  }
  .wallet-crypto-dropdown-bottom {
    margin-top: 20px;
  }
  .wallet-scroll-whole {
    max-height: 280px;
  }
  .wallet-crypto-dropdown-bottom {
    margin-top: 0;
  }

  /* .wallet-dropdown-field-div1{ display: block !important; margin-top: 20px; }
.wallet-dropdown-field-div1 > select{ width: 100% !important; margin-bottom: 15px; }
.wallet-dropdown-field-div1 > div{ margin-bottom: 20px; }
.wallet-dropdown-field-div2{ display: block !important; margin-top: 20px; }
.wallet-dropdown-field-div2 > select{ width: 100% !important; margin-bottom: 15px; }
.wallet-dropdown-field-div2 > div{ text-align: center; display: block !important; }
.wallet-dropdown-field-div2 > div .swap-get-field{ display: block; text-align: center !important; width: 100% !important; margin-top: 5px; } */
  .hotKeys-help-hide-sm {
    display: none;
  }
  .wallet-dropdown-field-max {
    justify-content: space-between !important;
    width: 46%;
  }
  .wallet-dropdown-field-max > button {
    min-width: 40px;
  }
  .wallet-dropdown-field-max > div {
    width: 100% !important;
    display: block !important;
    text-align: center;
  }
  .wallet-dropdown-field-max > div .swap-send-field {
    width: 100% !important;
  }
  .transaction-popup {
    padding: 15px 5px !important;
  }
  .modals-height-on-mobiles {
    height: 100% !important;
    width: 100% !important;
  }
  .modals-height-on-mobiles.modals-height-on-mobiles-spinner {
    width: 400px !important;
  }
  .high-rolers-data-player *,
  .high-rollers-heading-row * {
    font-size: 12px !important;
  }
  .bets-history-header-container *,
  .bets-history-content-contnainer * {
    font-size: 11px !important;
  }
  .affiliate-search-records table th {
    font-size: 11px;
  }
  .affiliate-search-records table td {
    font-size: 11px;
  }
  .affiliate-search-records table td .user {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 23px;
  }
  .userWinAmount-coinsStack img {
    height: 110px;
    display: block;
    margin: auto;
  }
}
@media (max-width: 560px) {
  .userFeedback-form-action .leave-a-message-btn {
    display: block;
    margin-bottom: 13px !important;
  }
  .ref {
    width: 370px !important;
  }
  .finalMultiplier,
  .multiplier-container,
  .countdown-text {
    font-size: 14px !important;
  }
  .limbo-game {
    min-height: initial;
  }
  .multiplier {
    padding: 3rem 0 3rem 0;
    font-size: 4rem;
    margin: 3rem 0 3rem 0;
  }
}
@media (max-width: 550px) {
  .round-user-data-inner {
    padding: 3px 10px !important;
  }
  .betchart-class .graph.relative {
    height: 200px !important;
  }
}
@media (max-width: 520px) {
  .signup-spin-main-containr {
    transform: scale(0.6);
  }
}
@media (max-width: 500px) {
  .currency-selection-main.absolute {
    width: 22.25rem;
  }
  .wallet-inner-tabs-main {
    width: 100% !important;
    height: 100% !important;
  }
  .ref {
    width: 370px !important;
  }
  .css-xs0225 {
    width: 100% !important;
    height: 100% !important;
    background: none;
  }
  .wallet-inner-tabs-content .inner-tabs-button {
    padding: 8px !important;
  }
  .wallet-scroll-whole {
    min-height: 450px;
    max-height: 450px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .hide-on-500px {
    display: none;
  }
  .mybets-id-time-conatiner {
    width: 35% !important;
  }
  .mybets-payout-profit-container {
    width: 42% !important;
  }
  .myBetsData-id-price-container {
    width: 46% !important;
  }
  .myBetsData-payout-profit-container {
    width: 43% !important;
  }
  .wallet-coin-balance {
    font-size: 13px;
    flex-direction: column;
    text-align: right;
  }
  .wallet-crypto-dropdown-top {
    font-size: 13px;
  }
  .wallet-notice {
    font-size: 13px !important;
    text-align: center !important;
    display: block;
  }
  .supportDeposit-heading span {
    font-size: 13px;
  }
  .wallet-security {
    font-size: 13px;
  }
  .wallet-security button {
    font-size: 13px !important;
  }
  .withdraw-adress span {
    font-size: 13px !important;
  }
  .withdraw-amount span {
    font-size: 13px !important;
  }
  .wallet-qr-qr-code-container {
    padding: 7px;
  }
  .support-deposit-container {
    display: none;
  }
  .walletOptions-container {
    display: none;
  }
  .swap-send-field {
    font-size: 14px !important;
  }
  .swap-get-field {
    font-size: 14px !important;
  }
  .highrollers-coin-img {
    margin-left: 3px !important;
  }
  .wallet-inner-middle > div {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 25px;
  }
  .swap-get-approximately {
    font-size: 14px;
  }
  .betchart-class .graph.relative {
    height: 180px !important;
  }
  .graph-connecting-size {
    font-size: 16px !important;
  }
  .chat-main-box {
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    height: 100% !important;
  }
  .chat-main-box .global-chat-messages-container {
    position: absolute;
    top: 50px;
    bottom: 80px;
    height: auto !important;
    left: 0;
    right: 0;
  }
  .multiplier-left-digits,
  .multiplier-right-digits,
  .multiplier--digits {
    font-size: 35px !important;
  }
  .multiplier-x {
    font-size: 30px !important;
  }
  .message-sender-action {
    bottom: 0px !important;
  }
}
@media (max-width: 480px) {
  .tab-stitch-transection-history {
    padding: 13px !important;
    font-size: 10px !important;
  }
  .transections-history-bill-content {
    flex-direction: column;
  }
  .transections-history-bill-left {
    width: 100% !important;
    padding: 0px !important;
    height: unset !important;
  }
  .tarnsections-history-bill-right {
    width: 100% !important;
  }
}
@media (max-width: 470px) {
  .history-myHistory-switch {
    width: auto;
  }
  .ref {
    width: 360px !important;
  }
  .history-myHistory-switch .my_row_item_2 {
    width: auto;
    padding: 13px 11px;
  }
  .round-user-data-inner button:nth-child(1) {
    display: none;
  }
  .round-user-data-inner button:nth-child(2) {
    display: none;
  }
  .graph-bar-container {
    padding: 10px 10px 15px 10px !important;
  }
  .round-users-data-main {
    margin: 0 !important;
    flex-grow: 1;
  }
  .round-user-data-inner {
    gap: 6px !important;
    padding: 6px 10px !important;
  }
  .bankroll-container {
    width: 120px !important;
    margin-right: 4px;
  }
  .high-rolers-data-player *,
  .high-rollers-heading-row * {
    font-size: 10px !important;
  }
  .high-roller-data-player-name {
    width: 20%;
    overflow: hidden;
    gap: 0 !important;
  }
  .high-rollers-data-betAmmount {
    width: 44%;
    gap: 0 !important;
  }
  .high-rollers-data-multiplier {
    width: 11%;
    gap: 0 !important;
  }
  .high-rollers-data-profit-amount {
    width: 25%;
    gap: 0 !important;
  }
  .round-user-data-inner {
    padding: 5px 10px !important;
    justify-content: space-between !important;
  }
  .coinsSelect {
    font-size: 14px;
  }
  .swap-fee {
    font-size: 12px;
  }
  .multiplierIndicator {
    width: 8px !important;
    height: 8px !important;
  }
}
@media (max-width: 460px) {
  .userprofile-logout-extend {
    right: 0px !important;
    width: 320px !important;
  }
  .multiplier {
    padding: 2rem 0 2rem 0;
    margin: 2rem 0 2rem 0;
  }
}
@media (max-width: 450px) {
  .wallet-qr-typo-container {
    width: 130px;
  }
  .ref {
    width: 350px !important;
  }
  .wallet-qr-qr-code-container canvas {
    width: 130px !important;
    height: 130px !important;
  }
  .betchart-class .graph.relative {
    height: 160px !important;
  }
  .individual-player-ammount-container {
    font-size: 11px !important;
  }
  .individual-player-NameImg-container > div:nth-child(4) span {
    font-size: 11px !important;
  }
  .individual-player-NameImg-container > div:nth-child(2) p {
    font-size: 11px !important;
  }
  .individual-player-NameImg-container > div:nth-child(4) {
    font-size: 11px !important;
  }
  .individual-player-NameImg-container > div:nth-child(1) p {
    font-size: 11px !important;
  }
  .individual-player-coin-container.individualPlayer-imgs img {
    width: 14px;
    height: 14px;
  }
  .individual-player-NameImg-container > div:nth-child(4) img {
    width: 14px;
    height: 14px;
  }
  .refere-page-buttons {
    text-align: center;
  }
  .user-amount-selection-inner .user-amount-selection-button {
    width: 46%;
    margin: 2% !important;
    text-align: center;
  }
  .currency-button-symbol {
    display: none;
  }
  .methods-container .payment-method {
    width: 100%;
  }
  .signup-spin-main-containr {
    transform: scale(0.45);
  }
}
@media (max-width: 400px) {
  .individual-player-NameImg-container > div:nth-child(1) {
    width: 18%;
  }
  .ref {
    width: 360px !important;
  }
  .individual-player-NameImg-container > div:nth-child(1) div img {
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
  }
  .wallet-content-class button span svg {
    display: none !important;
  }
  .my-bet-history-item *,
  .my-history-content {
    font-size: 10px !important;
  }
  .round-user-data-container .justified {
    gap: 4px !important;
  }
  .round-user-data-container .players-click-round-id {
    font-size: 11px !important;
  }
  .round-user-data-container .players-click-multiplier {
    font-size: 12px !important;
  }
  .round-user-data-inner {
    padding: 7px 10px !important;
  }
  .wallet-crypto-coins > div {
    padding: 10px 4px !important;
    gap: 3px !important;
    font-size: 13px;
  }
  .wallet-crypto-coins > div .coin-img-container img {
    max-width: 14px;
  }
  .wallet-qr-typo-container {
    width: 120px;
  }
  .wallet-qr-qr-code-container canvas {
    width: 115px !important;
    height: 115px !important;
  }
  .qr-hash-container {
    font-size: 11px;
  }
  .countdown-text {
    font-size: 13px !important;
  }
  .betchart-class .graph.relative {
    height: 140px !important;
  }
  .wallet-popup-steps .bonus-item {
    padding-top: 5px !important;
  }
  .wallet-popup-steps .bonus-item .bonus-box {
    padding-left: 35px;
  }
  .wallet-popup-steps .bonus-item .bonus-box .img {
    width: 28px;
    height: 28px;
  }
  .wallet-popup-steps .bonus-item .bonus-box .bonus-num {
    line-height: 14px;
    font-size: 12px;
  }
  .wallet-popup-steps .bonus-item .bonus-box .bonus-txt {
    font-size: 10px;
  }
  .affiliate-toggle-icon {
    margin-right: 0;
  }
  .graph-connecting-size {
    width: 87%;
    transform: translate(0%, 0%) !important;
    left: 35px !important;
    top: 25% !important;
    text-align: center;
  }
  .runMultiplier {
    justify-content: center;
  }
  .multiplier-left-digits,
  .multiplier-right-digits,
  .multiplier--digits {
    font-size: 25px !important;
  }
  .bang-on-small-devices {
    display: flex;
    justify-content: center;
  }
  .multiplier-x {
    font-size: 20px !important;
  }
}


@media (max-width:360px) {
	.userprofile-logout-extend span {
		font-size: 10px;
	}

	.userprofile-logout-extend svg {
		font-size: 10px;
	}
}

@media (max-width:370px) {
	.hide-on-370px {
		display: none;
	}

	.ref {
		width: 350px !important;
	}

	.total-players-table-header {
		padding: 10px 5px !important;
		font-size: 10px;
	}

	.pay-out-profit img {
		max-width: 15px !important;
	}
}

.second-scaler-animation {
	animation: scaler2 1s ease-in-out;
}
@keyframes scaler2 {
	0% {
		scale: 0;
		opacity: 0%;
	}

	100% {
		scale: 1;
		opacity: 100%;
	}
}
.crash-bets-stats-conainer {
    margin-top: 80px;
}

.crash-data-container::-webkit-scrollbar {
    display: none;
}

.crash-data-container {
    background: #1c1e22;
    border-radius: 5px;
    color: white;
    width: 39.1%;
    margin-bottom: 30px;
    overflow: hidden;
}

.crash-bets-stats-conainer {
    margin-top: 12px;
    background: #1c1e22;
    padding: 20px 0;
    border-radius: 20px;
    width: 95%;
}

.Crash-graph-container {
    width: 59.764%;
    margin-bottom: 30px;
}